import React from "react";
import CanvasWrapper from "../CanvasWrapper";
import MouseContainer from "../mousecontainer";
import { useParams } from "react-router-dom";


const Board = () => {
  return (
    <>
      <CanvasWrapper />
      {/* <BoardNav />
      <IconBar />
      <AntSideBar /> */}
    </>
  );
};

export default Board;
