import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import Canvas from "../canvas";
import { useBoardFile } from "../../context/boardFileContext";
import { useCanvas } from "../../context/canvasContext";
import { v1 as uuid } from "uuid";
import { debounce } from 'lodash';

import {
  addObj,
  addPath,
  addText,
  emitCanvasFile,
  emitDrawingPath,
  emitModify,
  modifyObj,
  removeObject,
  addImage,
  emitClonedObj,
  addClonedObject,
  addToGroup,
  emitRoomID,
  emitCanvasData,
  onBackgroundColorChange,
  clearCanvasListen,
  onShapeColorChange,
  ListenUndo,
  ListenRedo,
  reloadStack,
  listenSendToBack,
  listenBringToFront,
  onStrokeColorChange,
  listenUngroup
} from "../../socket";
import axios from "axios";
import { workwiseServices } from "../../api";
import { useDocument } from "../../context/documentInfoContex";



const CanvasWrapper = () => {
  const { saveBoardData, loadBoardFile, boardFileName } = useBoardFile();
  const [update,setUpdate]=useState(null)
  const {
    canvasRef,
    prepareCanvas,
    createGrid,
    Panning,
    createImage,
    getActiveObject,
    saveStateToUndoStack,
    undoStack,
    redoStack,
    reloadStack,
    saveStateToReloadStack,
    documentData,
    savingLastChanges,
    lastChange
   
  } = useCanvas();

  const {setSavingData,isReadOnly} = useDocument();
  
  let { id } = useParams();
  const [boardData, setBoardData] = useState();
  const [movementData, setMovementData]= useState();
  const url_string = window.location.href; //window.location.href
  const url = new URL(url_string);
  const mileboardName = url.searchParams.get("mileboardname");
  //Send the id to the server.
  //Checks if the board exists, if not. It creates the board.
  //If it does, it loads the while.
  React.useEffect(() => {
    //emitCanvasFile(id);
    emitRoomID(id);
  }, [id]);

  //UseEffect to hit the Workwise Api

  // const [saveDocumentDebounced] = useState(() =>
  //   debounce((boardId) => {
  //     const saved='Saved'
  //   savingLastChanges(saved)
  //   return workwiseServices.saveDocumentlastUpdate(boardId)}, 2000)
  // );

  // useEffect(() => {
  //   // Save Debouned after user has stopped activity
  //   if (boardData) {
  //     saveDocumentDebounced(id);
  //     return saveDocumentDebounced.cancel; // Clean up by canceling debounce on unmount
  //   }
  // }, [boardData, id, saveDocumentDebounced]);

  // React.useEffect(()=>{
  //   const saved='Saving...'
  //   if (boardData) {

  //     savingLastChanges(saved)
  //   } 
  // },[boardData])
  
  const baseURL='https://board.workw.com/api/';
  // const baseURL = "http://localhost:3000/api/";

  React.useEffect(() => {
    //loadBoardFile(id, canvasRef);
    const loadBoardData = async (id,canvasRef) => {
      try {
        // document.getElementById("mileboard-name").innerHTML = mileboardName;
        const response = await axios.get(
          `${baseURL}boards/${id}`
        );
        // console.log(response.data[0].mileboardData[0])
        canvasRef.current.loadFromJSON(response.data[0].mileboardData[0]);
        canvasRef.current.renderAll();
        saveStateToReloadStack();
        console.log('reloadStack',reloadStack)
      } catch (error) {
        console.log(error);
      }
    };
    loadBoardData(id,canvasRef);
  }, [id, canvasRef]);

  React.useEffect(() => {
    //loadBoardFile(id, canvasRef);
    const createBoard = async (id,canvasRef) => {
      try {
        const response = await axios.post(
          `${baseURL}boards/create`,
          {id}
        );
        
      } catch (error) {
        console.log(error);
      }
    };
    createBoard(id, canvasRef);
  }, [id, canvasRef]);
  
  //Destructuring the two main functions that'll initalize the
  // canvas component from the CanvasContext.
  
  //prepare initial canvas.
  React.useEffect(() => {
    prepareCanvas(id);
   // createBackgroundCanvas();
  }, [prepareCanvas]);

  React.useEffect(() => {
    window.addEventListener("dragover", (event) => {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
    });
    if(isReadOnly){
    window.addEventListener("drop", (event) => {
      event.stopPropagation();
      event.preventDefault();
      const fileList = event.dataTransfer.files;
      for (const file of fileList) {
        if (file.type && !file.type.startsWith("image/")) {
          // console.log("File is not an image.", file.type, file);
          return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
          let imgDataBase64 = event.target.result;
          createImage(imgDataBase64,id);
        });
        reader.readAsDataURL(file);
      }
    });
  }

    // window.addEventListener("copy", copyItem);
    // let _clipboard;
    // // async function pasteItem(e) {
    // //   if (canvasRef.current.getActiveObject()) {
    // //     _clipboard.clone(function (clonedObj) {
    // //       canvasRef.current.discardActiveObject();
    // //       clonedObj.set({
    // //         left: clonedObj.left + 10,
    // //         top: clonedObj.top + 10,
    // //         evented: true,
    // //       });
    // //       if (clonedObj.type === "activeSelection") {
    // //         // active selection needs a reference to the canvas.
    // //         clonedObj.canvas = canvasRef.current;
    // //         clonedObj.forEachObject(function (obj) {
    // //           console.log("I occurred")
    // //           console.log(canvasRef.current.getObjects().indexOf(canvasRef.current.setActiveObject(obj)))
    // //           canvasRef.current.add(obj);
    // //           //emitClonedObj(obj);
    // //           clonedObj.setCoords();
    // //         });
    // //         // this should solve the unselectability
           
    // //       } else {
    // //         canvasRef.current.add(clonedObj);
    // //         emitClonedObj(clonedObj);
    // //         clonedObj.setCoords();
    // //    }
    // //       _clipboard.top += 10;
    // //       _clipboard.left += 10;
    // //       canvasRef.current.setActiveObject(clonedObj);
    // //       canvasRef.current.requestRenderAll();
    // //     });
    //   }

      const pasteItem = async () => {
        try {
          const clipboardItems = await navigator.clipboard.read();
          for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
              const blob = await clipboardItem.getType(type);
              let imgURL = URL.createObjectURL(blob);
              createImage(imgURL);
            }
          }
        } catch (err) {
          console.error(err.name, err.message);
        }
      }
      window.addEventListener("paste", pasteItem);
  }, [canvasRef, createImage]);

  React.useEffect(() => {
    Panning(id);
  }, [Panning]);

  //React.useEffect(() => {
  //  createGrid();
  //}, [createGrid]);



  //resize canvas according to screensize.
  // React.useEffect(() => {
  //   const resizeCanvas = () => {
  //     canvasRef.current.setDimensions({
  //       width: width,
  //       height: height,
  //     });
  //     canvasRef.current.renderAll();
  //   };
  //   window.addEventListener("resize", resizeCanvas);
  //   return () => window.removeEventListener("resize", resizeCanvas);
  // }, [canvasRef, width, height]);

  //event listeners for canvas
  React.useEffect(() => {
    if (canvasRef) {
      //Executed when an object is modified, i.e the dimensions,color etc.
      canvasRef.current.on("object:modified", function (options) {
        if (options.target) {
          const modifiedObj = {
            obj: options.target,
            id: options.target.id,
          };
          // console.log(canvasRef.current.toJSON(modifiedObj));
          setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
          emitModify(id,modifiedObj);
          //saveBoardData(id, canvasRef);
        }
      });
      //Executed when text is changed.
      canvasRef.current.on("text:changed", function (options) {
        if (options.target) {
          const modifiedTxt = {
            obj: options.target,
            id: options.target.id,
          };
          // console.log(modifiedTxt);
          // console.log(canvasRef.current.toJSON(modifiedTxt));
          setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
          emitModify(id,modifiedTxt);
          saveBoardData(id, canvasRef);
        }
      });
      //Executed when the object is moving.
      canvasRef.current.on("object:moving", function (options) {
        if (options.target) {
          const modifiedObj = {
            obj: options.target,
            id: options.target.id,
          };
          // console.log(canvasRef.current.toJSON(modifiedObj));
          setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
	        setMovementData(modifiedObj);
         // emitModify(modifiedObj);
          saveBoardData(id, canvasRef);
        }
      });
      canvasRef.current.on("path:created", function (e) {
        let path = e.path;
        path.set({ id: uuid() });
        path.set({ type: "path" });
        // console.log(canvasRef.current.toJSON({path}));
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
        saveStateToUndoStack()                  //Path created with pen tool saves in UndoStack here                     //Implemented to make Undo Redo work for Pen tool
        emitDrawingPath({room:id, path, id: path.id });
        saveBoardData(id, canvasRef);
      });
      canvasRef.current.on("object:removed", function (e) {
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      });

      modifyObj(canvasRef,saveStateToUndoStack,setBoardData);
      addObj(canvasRef,saveStateToUndoStack,setBoardData);
      addText(canvasRef,saveStateToUndoStack,setBoardData);
      addPath(canvasRef,saveStateToUndoStack,setBoardData);
      removeObject(canvasRef,saveStateToUndoStack,setBoardData);
      addImage(canvasRef,saveStateToUndoStack,setBoardData);
      addClonedObject(canvasRef,saveStateToUndoStack,setBoardData);
      addToGroup(canvasRef,saveStateToUndoStack,setBoardData);
      //Below Function is Socket Listener Function which listens to background changes and reflects in connected clients,setBoardData C
      onBackgroundColorChange(canvasRef,saveStateToUndoStack,setBoardData)
      //Function below Listen to Server when clear-canvas is emitt,setBoardDataed
      clearCanvasListen(canvasRef,saveStateToUndoStack,setBoardData);
      onShapeColorChange(canvasRef,saveStateToUndoStack,setBoardData);
      //Listener to listen to Undo Emit from Serv,setBoardDataer
      ListenUndo(canvasRef,undoStack,redoStack,saveStateToUndoStack,setBoardData);
      //Listener to listen to Redo Emit from Serv,setBoardDataer
      ListenRedo(canvasRef,undoStack,redoStack,saveStateToUndoStack,setBoardData);
      listenSendToBack(canvasRef,saveStateToUndoStack,setBoardData);
      listenBringToFront(canvasRef,saveStateToUndoStack,setBoardData);
      onStrokeColorChange(canvasRef,saveStateToUndoStack,setBoardData)
      listenUngroup(canvasRef,saveStateToUndoStack,setBoardData)

    }
  }, [canvasRef, saveBoardData, boardFileName, getActiveObject, id]);

  React.useEffect(() => {
    if (boardData && boardData.length !== 0) {
      const updateBoardData = async (data) => {
        await axios.post(
          `${baseURL}boards/update`,
          {
            mileboardID: id,
            mileboardData: data,
          }
        );
        workwiseServices.saveDocumentlastUpdate(id, setSavingData,data);
      };

      let timer = setTimeout(async () => {
        updateBoardData(boardData);
      }, 100);

      return () => clearTimeout(timer);

    }
  }, [boardData, undoStack, redoStack]);

  React.useEffect(() => {
   let timer = setTimeout(() => {
    emitModify(movementData);
	  //  console.log("timer function called");
   },500)

   return () => clearTimeout(timer);	  

  },[movementData])


  return (
    <>
    <div style={{pointerEvents:isReadOnly?'none':null}} id = 'my-canvas-board'>
      <Canvas />
      </div>
    </>
  );
};

export default CanvasWrapper;
