import React,{useEffect} from 'react';
import { Button, Tooltip } from 'antd';
import { UndoOutlined, RedoOutlined } from '@ant-design/icons';
import { useCanvas } from '../context/canvasContext';
import { emitUndo,emitRedo } from '../socket';
import { useParams } from 'react-router';
import useMousePosition from '../hooks/useMousePosition';
import { useDocument } from '../context/documentInfoContex';

const UndoRedoToolbar = () => {
  const isMobile=window.screen.width<768
  const isSmallMobile = window.screen.width < 390 ;
  const isTablet = window.screen.width < 1200 && window.screen.width > 768;


  const{id}=useParams();
  const {x,y}=useMousePosition()
   let { canvasRef, undoStack, redoStack,documentData,reloadStack} = useCanvas();
   const { isReadOnly } = useDocument();
  const undoDisabled = undoStack.length === 0;
  const redoDisabled = redoStack.length === 0;

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === 'z' || event.key === 'Z') {
        undo();
      } else if (event.ctrlKey && event.key === 'y' || event.key === 'Y') {
        redo();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const undo = () => {
    if (undoStack.length > 0) {
      const currentState = undoStack.pop();
      redoStack.push(currentState);
      const previousState = undoStack[undoStack.length - 1];
      if (!previousState || previousState == 'undefined') {
        if (!reloadStack || reloadStack == 'undefined') {
          canvasRef.current.clear();

        }
        else {
          canvasRef.current.loadFromJSON(reloadStack[0], function () {
            canvasRef.current.renderAll();
          });
        }
      }
      else {
        canvasRef.current.loadFromJSON(previousState, function () {
          canvasRef.current.renderAll();
        });
      }
      emitUndo(id)
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      console.log("nextState", nextState, redoStack.length);
      if(nextState) {
        undoStack.push(nextState);
        canvasRef.current.loadFromJSON(nextState, function () {
          canvasRef.current.renderAll();
        });
      }
      else {
        redoStack =[];
      }
      emitRedo(id)
    }
  };
  

  // function undo() {
  //   console.log("Undo",)
  //   if (undoStack.length > 0) {
  //     console.log("Undo1",undoStack)
  //     console.log("Undo2",redoStack)
  //     let state = undoStack.pop();
    
  //     console.log(state);
  //     redoStack.push(JSON.stringify(canvasRef.current.toDatalessJSON(['selectable', 'id', 'type'])));
  //     canvasRef.current.loadFromJSON(state, function() {
  //       canvasRef.current.renderAll();
  //     });
  //   }
  // }
  
  // // Function to redo the last action by restoring the next state from the redo stack
  // function redo() {
  //   console.log("redo",redoStack)
  //   if (redoStack.length > 0) {
  //     let state = redoStack.pop();
  //     undoStack.push(JSON.stringify(canvasRef.current.toDatalessJSON(['selectable', 'id', 'type'])));
  //     canvasRef.current.loadFromJSON(state, function() {
  //       canvasRef.current.renderAll();
  //     });
  //   }
  // }

  // function clearUndoRedoStacks() {
  //   undoStack = [];
  //   redoStack = [];
  // }

  return (!isReadOnly &&
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile?'row':'column',
        width: isMobile?'100px':'80px',
        marginTop: 20,
        padding: 10,
        height: isMobile?'50px':'fit-content',
        boxShadow: 'rgb(101 92 92 / 50%) 0px 3px 8px',
        borderRadius: '5px',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: '#fff',
        position:isMobile?"absolute":"relative",
        zIndex:1,
        top:isMobile?isSmallMobile?'580px':'750px':'',
        left:isMobile?'265px':'',
        gap:isMobile?'15px':''
      }}
    >
      <Tooltip title="Undo" placement="right">
        <Button
          icon={<UndoOutlined />}
          shape="circle"
          style={{ marginBottom: isMobile?'':20 }}
          onClick={undo}
          disabled={undoDisabled}
        />
      </Tooltip>
      <Tooltip title="Redo" placement="right">
        <Button icon={<RedoOutlined />} shape="circle" onClick={redo} disabled={redoDisabled} />
      </Tooltip>
    </div>
  );
};

export default UndoRedoToolbar;