import React, { useContext, useState, createContext } from "react";
import { v1 as uuid } from "uuid";
import {
  emitCanvasFile,
  emitLoadCanvas,
  loadCanvasData,
  emitCanvasData,
} from "../socket";

const boardFileContext = createContext();

export const BoardFileProvider = ({ children,location,history,match }) => {
  const [boardFileName, setBoardFileName] = useState();

  const createBoardFile = () => {
    let id = uuid();
    // //emit the board file name to the server.
    emitCanvasFile(id);
    //emit the same board file name to state.
    setBoardFileName(id);
    emitCanvasData(id, { path: "null" });
  };

  const loadBoardFile = (id, canvasRef) => {
    //emit event load file with boardFileName.
    if (id) {
      emitLoadCanvas(id);
      loadCanvasData(canvasRef);
    }
  };

  const saveBoardData = (id, canvasRef) => {
    //convert board data into json, write that board data
    //to the server.
    let canvasData = canvasRef.current.toJSON(["id", "selectable", "type"]);
    emitCanvasData(id, canvasData);
  };

  return (
    <boardFileContext.Provider
      value={{ createBoardFile, saveBoardData, loadBoardFile, boardFileName }}
    >
      {children}
    </boardFileContext.Provider>
  );
};

export const useBoardFile = () => useContext(boardFileContext);
