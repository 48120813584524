import { io } from "socket.io-client";
import { fabric } from "fabric";

const URL = "https://board.workw.com/";
//Added local Socket Server for Testing
// const URL = "http://localhost:3001";
const socket = io(URL);
// emitters

//Emitters for Chat Implemented here
export const emitLiveComments = (data) => {
  socket.emit("comments", data);
};
//Event fired when an object is added onto the board.
export const emitAdd = (obj) => {
  socket.emit("object-added", obj);
};

export const emitClonedObj = (id,obj,DocID) => {
  let objectInfo={id,obj,DocID}
  socket.emit("cloned-obj", objectInfo);
};

//This event is fired when an object is modified, specifically the dimensions.
export const emitModify = (id,obj) => {
  socket.emit("object-modified", id,obj);
};
//Emits an event "text-added" that sends a text object with all of its properties.
export const emitText = (obj) => {
  let textObj = obj;
  socket.emit("text-added", obj);
};
//Emits an event "path:created" that sends the drawn path with all of its properties to the
//server.
export const emitDrawingPath = (obj) => {
  socket.emit("path-created", obj);
};
//Emits mouse coordinates to the server.
export const emitMousePositions = (obj) => {
  if(obj.userName){
  socket.emit("mouse-positions", obj)};
};
//emits canvas data to the server.
export const emitCanvasData = (id, canvasData) => {
  const data = { id, canvasData };
  socket.emit("save-canvas", data);
  // console.log('canvasData',canvasData)
};
export const emitCanvasFile = (id) => {
  const ID = id;
  socket.emit("board-created", ID);
};
//emits data of removed object to the server.
export const emitDeleteObject = (objID) => {
  socket.emit("object-removed-id", objID);
};
export const emitLoadCanvas = (boardName) => {
  socket.emit("load-canvas", boardName);
};
export const emitImageData = (imgData) => {
  socket.emit("image-data", imgData);
};

export const emitObjID = (objID) => {
  socket.emit("group", objID);
};

export const emitRoomID = (roomID) => {
  socket.emit("room-id",roomID);
}
//Emit to Clear Canvas sent to Server
export const emitClearCanvas=(data)=>{
  // const data = { id, canvasData };
  socket.emit("clear-canvas",data)
}
//Emitter to send Undo to Server
export const emitUndo=(roomID)=>{
  socket.emit('undo',roomID)
  // console.log("Undo Sending to Server",roomID)
}

export const emitRedo=(roomID)=>{
  socket.emit('redo',roomID)
  // console.log("Redo Sending to Server",roomID)
}

export const emitSendToBack=(Obj)=>{
  socket.emit('sendToBack',Obj)
  // console.log('Emit Send To Back Firred',Obj)
}

export const emitBringToFront=(Obj)=>{
  socket.emit('bringToFront',Obj)
  // console.log('Emit Send To Back Firred',Obj)
}

export const emitUnGroup=(data)=>{
  socket.emit('ungroup',data)

}
// listeners

export const listenUngroup=(canvasRef,saveStateToUndoStack,setBoardData)=>{
  socket.on('ungroup', (data) => {
    const { groupId } = data;

    // Find the group by ID
    const group = canvasRef.current.getObjects().find((obj) => obj.id === groupId);
    // console.log('group : ',group)

    // if (obj !== null) {
      // const { obj, id } = data;
      canvasRef.current.getObjects().forEach((object) => {
        // console.log('33 object From Send To Back LISTNER',object)
        if (object === group) {
          // set the object on the canvas to the object we received from the socket server
          object.toActiveSelection();
          canvasRef.current.discardActiveObject();


          // calling setCoords ensures that the canvas recognizes the object in its new position
          // object.sendToBack()
          canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
          saveStateToUndoStack()
        //  console.log('SEND TO BACK saveStateToUndoStack()')    

        }
      });
  });
}


export const listenSendToBack=(canvasRef,saveStateToUndoStack,setBoardData)=>{
  // console.log('LISTENER Send To Back Firred')

  socket.on('sendToBack',(Obj)=>{
    let obj=Obj.activeObject;
    let id=Obj.objectID
    // console.log('11 LISTENER Send To Back Firred') 
    // console.log('22 SEND TO BACK Listner',Obj.objectID) 
    if (obj !== null) {
      // const { obj, id } = data;
      canvasRef.current.getObjects().forEach((object) => {
        // console.log('33 object From Send To Back LISTNER',object)
        if (object.id === id) {
          // set the object on the canvas to the object we received from the socket server
          object.sendToBack()
          // calling setCoords ensures that the canvas recognizes the object in its new position
          // object.sendToBack()
          canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
          saveStateToUndoStack()
        //  console.log('SEND TO BACK saveStateToUndoStack()')    

        }
      });
    }

  })
}

export const listenBringToFront=(canvasRef,saveStateToUndoStack,setBoardData)=>{
  // console.log('LISTENER Send To Back Firred')

  socket.on('bringToFront',(Obj)=>{
    let obj=Obj.activeObject;
    let id=Obj.objectID
    // console.log('11 LISTENER Send To Back Firred') 
    // console.log('22 SEND TO BACK Listner',Obj.objectID) 
    if (obj !== null) {
      // const { obj, id } = data;
      canvasRef.current.getObjects().forEach((object) => {
        // console.log('33 object From Send To Back LISTNER',object)
        if (object.id === id) {
          // set the object on the canvas to the object we received from the socket server
          object.bringToFront()
          // calling setCoords ensures that the canvas recognizes the object in its new position
          // object.sendToBack()
          canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
          saveStateToUndoStack()
        //  console.log('SEND TO BACK saveStateToUndoStack()')    

        }
      });
    }

  })
}

export const addObj = (canvasRef,saveStateToUndoStack,setBoardData) => {
  // we call socket.off first in case the socket connection has been restarted - this will remove any
  // listens for the socket server to broadcast a 'new-add' event.
  socket.on("new-add", (data) => {
    // we'll pull out the object and id from the data object the socket emitted
    const { obj, id } = data;
    let object;
    // check the type of the obj we received and create an object of that type
    if (obj.type === "rect") {
      object = new fabric.Rect({
        height: obj.height,
        width: obj.width,
        fill: obj.fill,
        top: obj.top,
        left: obj.left,
        stroke: obj.stroke,
        strokeWidth: obj.strokeWidth,
      });
    } 
     if (obj.type === "circle") {
      object = new fabric.Circle({
        radius: obj.radius,
        fill: obj.fill,
        top: obj.top,
        left: obj.left,
        stroke: obj.stroke,
        strokeWidth: obj.strokeWidth,
      });
    } 
     if (obj.type === "triangle") {
      object = new fabric.Triangle({
        width: obj.width,
        height: obj.height,
        fill: obj.fill,
        top: obj.top,
        left: obj.left,
        stroke: obj.stroke,
        strokeWidth: obj.strokeWidth,
      });
    }
    //Added Ellipse Shape to reflect in connected Clients
     if(obj.type === "ellipse"){
      object = new fabric.Ellipse({
        rx: obj.rx,
        ry: obj.ry,
        fill: obj.fill,
        top: obj.top,
        left: obj.left,
        stroke: obj.stroke,
        strokeWidth: obj.strokeWidth,
      });
    }
    // set the new object's id to the id we received
    object.set({ id: id });
    // add the object to the canvas
    canvasRef.current.add(object);
    // saveStateToUndoStack()
    canvasRef.current.renderAll();
    setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
    saveStateToUndoStack()
  
  });
};

export const modifyObj = (canvasRef,saveStateToUndoStack,setBoardData) => {
  // listens for the socket server to broadcast a 'new-modification' event
  socket.on("new-modification", (data) => {
    
    // check the objects on our canvas for one with a matching id
    if (data !== null) {
      const { obj, id } = data;
      // console.log('Objects for Delete from Socket Modify',obj)
      if(obj.type=="activeSelection"){
        canvasRef.current.getObjects().forEach((item) => {
        obj.objects.forEach((object)=>{
          if (item.id===object.id) {
            // set the object on the canvas to the object we received from the socket server
            item.set(object);
            // calling setCoords ensures that the canvas recognizes the object in its new position
            item.setCoords();
            canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));

            saveStateToUndoStack()
      // console.log('saveStateToUndoStack()')    
          }

        })
      })

      }else{
      canvasRef.current.getObjects().forEach((object) => {
        if (object.id === id) {
          // set the object on the canvas to the object we received from the socket server
          object.set(obj);
          // calling setCoords ensures that the canvas recognizes the object in its new position
          object.setCoords();
          canvasRef.current.renderAll();
          saveStateToUndoStack()
    // console.log('saveStateToUndoStack()')    

        }
      });
    }
    }
   
  });
};

export const addText = (canvasRef,saveStateToUndoStack,setBoardData) => {
  //listens for the socket server to broadcast a "text-added" event.
  socket.on("text-added", (data) => {
    //Pull out the object and the id that was sent in the data packet/object.
    const { obj, id } = data;
    //create an object with the properties of the object received in data.
    let object = new fabric.Textbox(obj.text, {
      fontSize: obj.fontSize,
      left: obj.left,
      top: obj.top,
      width: obj.width,
      fontWeight: obj.fontWeight,
      fontFamily: obj.fontFamily,
    });
    //set the id of the object to the id that was received from the data object.
    object.set({ id: id });
    //add the current object and re-render the canvas once again.
    canvasRef.current.add(object);
    canvasRef.current.renderAll();
    setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
    saveStateToUndoStack()
  });
};

export const addPath = (canvasRef,saveStateToUndoStack) => {
  socket.on("path-created", (data) => {
    const { path, id } = data;
    //console.log(path.path);
    //Converts Array of path coordinates required by
    // add path, objects into a string.
    let pathCoords = path.path
      .map((chunk) => {
        return chunk.join(" ");
      })
      .join(" ");
    let newPath = new fabric.Path(pathCoords, {
      type: "path",
      version: "4.5.1",
      originX: path.originX,
      originY: path.originY,
      left: path.left,
      top: path.top,
      width: path.width,
      height: path.height,
      fill: path.fill,
      stroke: path.stroke,
      strokeWidth: path.strokeWidth,
      strokeDashArray: path.strokeDashArray,
      strokeLineCap: path.strokeLineCap,
      strokeDashOffset: path.strokeDashOffset,
      strokeLineJoin: path.strokeLineJoin,
      strokeUniform: path.strokeUniform,
      strokeMiterLimit: path.strokeMiterLimit,
      scaleX: path.scaleX,
      scaleY: path.scaleY,
      angle: path.angle,
      flipX: path.flipX,
      flipY: path.flipY,
      opacity: path.opacity,
      shadow: path.shadow,
      visible: path.visible,
      backgroundColor: path.backgroundColor,
      fillRule: path.fillRule,
      paintFirst: path.paintFirst,
      globalCompositeOperation: path.globalCompositeOperation,
      skewX: path.skewX,
      skewY: path.skewY,
    });
    newPath.set({ id: id });
    canvasRef.current.add(newPath);
    canvasRef.current.renderAll();
    saveStateToUndoStack()
  });
};

export const renderMouse = (setConnectedSocketID) => {
  socket.on("mouse-positions", (info) => {
    const { sessionID, serverX, serverY } = info;
    setConnectedSocketID(info);
  });
};
export const loadCanvasData = (canvasRef) => {
  socket.on("load-canvas", (data) => {
    canvasRef.current.loadFromJSON(data);
    canvasRef.current.renderAll();
  });
};

export const removeObject = (canvasRef,saveStateToUndoStack,setBoardData) => {
  socket.on("object-removed-id", (data) => {
    // console.log("DATA from Socket", data);
    let activeObjects = canvasRef.current;
    // console.log("Objects on canvas", activeObjects);

    activeObjects._objects.forEach((object) => {
      if (object.id === data) {
        canvasRef.current.remove(object);
      }
    });

    canvasRef.current.renderAll();
    setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
    saveStateToUndoStack()
  });
};

export const addImage = (canvasRef,saveStateToUndoStack,setBoardData) => {
  socket.on("image-data", (imgData) => {
    const { id, image } = imgData;
    // console.log("Add Image Listner",imgData);
    // if(imgData.image.type='group'){
    //   imgData.image.objects.map((image)=>{

    //     const imgObj = new Image();
    //     imgObj.src = image.src;
    //     imgObj.onload = function () {
    //       const newImg = new fabric.Image(imgObj);
    //       newImg.set({
    //         id: id,
    //         scaleX: image.scaleX,
    //         scaleY: image.scaleY,
    //         angle: image.angle,
    //       });
    //       canvasRef.current.add(newImg).renderAll();
    //     }
        
    //   })
      
    // } else{
    const imgObj = new Image();
    imgObj.src = image.src;
    imgObj.onload = function () {
      const newImg = new fabric.Image(imgObj);
      newImg.set({
        id: id,
        scaleX: image.scaleX,
        scaleY: image.scaleY,
        angle: image.angle,
      });
      canvasRef.current.add(newImg).renderAll();

}
      saveStateToUndoStack()
      setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
    // };
  });
};

export const addTotalClients = (setNumberOfConnectedClients) => {
  socket.on("total-clients", (totalClients) => {
    setNumberOfConnectedClients(totalClients);
  });
};

export const connectedClients = (setConnectedSocketID) => {
  socket.on("connected-clients-ids", (connectedClientArr) => {
    // console.log(connectedClientArr);
    setConnectedSocketID(connectedClientArr);
  });
};

export const removeTotalClients = (setNumberOfConnectedClients) => {
  socket.on("disonnected-client", (totalClients) => {
    setNumberOfConnectedClients(totalClients);
  });
};


//NOT WORKING PROPERLY
export const addClonedObject = (canvasRef, saveStateToUndoStack, setBoardData) => {
  socket.on("cloned-obj", (objectInfo) => {
    const { obj, id } = objectInfo;
    let objectsToAdd = [];

    const createGroup = (groupObj) => {
      if (groupObj.type === 'group') {
        // Handle nested groups
        const nestedGroupObjects = groupObj.objects.map((nestedGroupObj) => {
          return createGroup(nestedGroupObj);
        });

        return new fabric.Group(nestedGroupObjects, {
          left: groupObj.left,
          top: groupObj.top,
          scaleX: groupObj.scaleX,
          scaleY: groupObj.scaleY,
        });
      } else {
        // Handle single object creation
        return createFabricObject(groupObj);
      }
    };

    if (obj.type === 'group') {
      // Handle top-level group creation
      objectsToAdd = obj.objects.map((groupObj) => {
        return createGroup(groupObj);
      });

      const group = new fabric.Group(objectsToAdd, {
        left: obj.left,
        top: obj.top,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
      });

      group.set({ id: id });
      canvasRef.current.add(group);
    } else {
      // Handle single object creation
      const object = createFabricObject(obj);
      object.set({ id: id });
      canvasRef.current.add(object);
    }

    canvasRef.current.renderAll();
    setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
    saveStateToUndoStack();
  });

  // Helper function to create fabric object based on type
  const createFabricObject = (obj) => {
    let fabricObject;

    switch (obj.type) {
      case 'rect':
        fabricObject = new fabric.Rect(obj);
        break;
      case 'circle':
        fabricObject = new fabric.Circle(obj);
        break;
      case 'triangle':
        fabricObject = new fabric.Triangle(obj);
        break;
      case 'ellipse':
        fabricObject = new fabric.Ellipse(obj);
        break;
      case 'image':
        fabricObject = new fabric.Image(obj);
        break;
      case 'i-text':
        fabricObject = new fabric.Textbox(obj.text, obj);
        break;
      case 'path':
        fabricObject = new fabric.Path(obj.path, obj);
        break;
      // Add more cases as needed
      default:
        console.error('Unknown object type:', obj.type);
    }

    return fabricObject;
  };
};


export const addToGroup = (canvasRef,saveStateToUndoStack,setBoardData) => {
  socket.on('group', (data) => {
    const {groupId, objIDArr, objArr } = data;
  
    // Filter the canvas objects that match the received objIDs
    const matchingObjects = [];
    objIDArr.forEach((id) => {
      const matchingObject = canvasRef.current.getObjects().find((obj) => obj.id === id);
      if (matchingObject) {
        matchingObjects.push(matchingObject);
      }
    });
  
    // Check if there are objects to group
    if (matchingObjects.length > 0) {
      const group = new fabric.Group(matchingObjects, {
        canvas: canvasRef.current,
      });
      group.set({id:groupId})
      // Add the group to the canvas
      canvasRef.current.add(group);
      matchingObjects.forEach((obj)=>canvasRef.current.remove(obj));

  
      // Refresh the canvas
      canvasRef.current.requestRenderAll();
      setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      saveStateToUndoStack()
    }
  });
  
}
//New Emitters and Listeners added For Recolor Background Feature

export const emitBackgroundColorChange = (roomID,color) => {
  socket.emit("background-color-change", roomID,color);
};

export const onBackgroundColorChange = (canvasRef,saveStateToUndoStack,setBoardData) => {
  socket.on("background-color-change", (color) => {
    let canvaBG = canvasRef.current;
    if (canvaBG) {
      canvasRef.current.set('backgroundColor', color);

      // Render the canvas with the new background color
      canvasRef.current.renderAll();
      setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      saveStateToUndoStack()
    }
  });
};

//Function to Listen Server Emitter clear-canvas Created here

export const clearCanvasListen = (canvasRef,saveStateToUndoStack,setBoardData)=>{  
  socket.on("clear-canvas",(data)=>{
    let {id,canvasData}=data;
    canvasRef.current.set('backgroundColor', '');
  
    canvasRef.current.remove(...canvasRef.current.getObjects());
    setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
    saveStateToUndoStack()
      })
}

export const emitShapeColorChange = (roomID,color,id) => {
  socket.emit("shape-color-change", roomID,color,id);
};

export const emitStrokeColorChange = (roomID,color,id) => {
  socket.emit("stroke-color-change", roomID,color,id);
};

export const onShapeColorChange = (canvasRef,saveStateToUndoStack,setBoardData) => {
  socket.on("shape-color-change", (color,id) => {
    const activeObjects = canvasRef.current
    if (activeObjects) {
      activeObjects._objects.forEach((object)=>{
        if(object.id===id){
          if(object.type=='group'){
            object._objects.map((item)=>{if(item.type!=='i-text'){item.set('fill', color)}});
        }
          object.set('fill',color)
          // object.set('stroke', color); // You can set the stroke color if needed

        }
      })

      // Render the canvas with the new background color
      canvasRef.current.renderAll();
      setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      saveStateToUndoStack()
    }
  });
};

export const onStrokeColorChange = (canvasRef,saveStateToUndoStack,setBoardData) => {
  socket.on("stroke-color-change", (color,id) => {
    const activeObjects = canvasRef.current
    if (activeObjects) {
      activeObjects._objects.forEach((object)=>{
        if(object.id===id){
          // object.set('fill',color)
          if(object.type=='group'){
            object._objects.map((item)=>{if(item.type!=='i-text'){item.set('stroke', color)}});
        }
          object.set('stroke', color); // You can set the stroke color if needed

        }
      })

      // Render the canvas with the new background color
      canvasRef.current.renderAll();
      setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      saveStateToUndoStack()
    }
  });
};

//Listener for Chats
export const listenerLiveComments = (updateMessageList) => {
  socket.on("comments", (data) => {
    data.color = "#98b3e6";
    data.margin = "5px auto 5px 0px"; //this styling work should be done through id
    updateMessageList(data);
  });
};


//Listener For Reflecting Undo
export const ListenUndo=(canvasRef,undoStack,redoStack,reloadStack,setBoardData)=>{

  socket.on("undo",()=>{
    if (undoStack.length > 0) {
      const currentState = undoStack.pop();
      redoStack.push(currentState);
      const previousState = undoStack[undoStack.length - 1];
      if (!previousState|| previousState=='undefined') {        
        if (!reloadStack || reloadStack == 'undefined') {
          // canvasRef.current.clear();
        }
        else {
          canvasRef.current.loadFromJSON(reloadStack[0], function () {
            canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
          });
        }
      }
      else
      {
      canvasRef.current.loadFromJSON(previousState, function () {
        canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      });
    }
    }
  })

}

//Listener For Reflecting Redo
export const ListenRedo=(canvasRef,undoStack,redoStack,setBoardData)=>{
  // let {undoStack, redoStack} = useCanvas();

  socket.on("redo",()=>{
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      // console.log("nextState", nextState, redoStack.length);
      if(nextState)
      {
        undoStack.push(nextState);
        canvasRef.current.loadFromJSON(nextState, function () {
          canvasRef.current.renderAll();
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
        });
      }
      else {
        redoStack =[];
        setBoardData(canvasRef.current.toJSON(["selectable", "id", "type"]));
      }
    }
  })

}

export default socket;
