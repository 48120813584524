import React, { useState } from "react";
import { Modal, Input, Button, Tooltip } from "antd";
import { useCanvas } from "../../context/canvasContext";
import { useParams } from "react-router-dom";

const ShapesModal = ({ visible, shapes, onCancel, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile=window.screen.width<768
  const isSmallMobile=window.screen.width<400;

  const {createCircle,createRectangle,createTriangle,createEllipse,createSquare,createCylinder,createRightAngleTriangle,createDiamond,createRightArrow} = useCanvas();
  
  const {id} = useParams();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredShapes = shapes.filter((shape) =>
    shape.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const createShape = (shapeName) => {
    console.log(shapeName);
    shapeName = shapeName.trim(); // trim whitespace
    console.log('name',shapeName);
    if (shapeName.toLowerCase() === 'circle') {
      createCircle(id);
    } else if (shapeName.toLowerCase() === 'rectangle') {
      console.log('inhere')
      createRectangle(id);
    } else if (shapeName.toLowerCase() === 'ellipse') {
      createEllipse(id);
    } else if (shapeName.toLowerCase() === 'square') {
      createSquare(id);
    } else if (shapeName.toLowerCase() === 'triangle') {
      createTriangle(id);
    } else if (shapeName.toLowerCase() === 'cylinder') {
      createCylinder(id);
    }
    else if (shapeName.toLowerCase() === 'right-angle-triangle') {
      createRightAngleTriangle(id);
    }
    else if (shapeName.toLowerCase() === 'diamond') {
      createDiamond(id);
    }
    else if (shapeName.toLowerCase() === 'arrow') {
      createRightArrow(id);
    }
  }


  return (
    <Modal
      title="Shapes"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div>
        <Input
          placeholder="Search shapes"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div>
      {filteredShapes.map((shape) => (
          <Tooltip key={shape.id} title={shape.name}>
            <Button
              style={{height:isMobile?isSmallMobile?55:70: 80,width:isMobile?isSmallMobile?55:70:80, margin: "10px"}}
              shape="circle"
              icon={shape.image}
              onClick={() => createShape(shape.name)}
            />
          </Tooltip>
        ))}
      </div>
    </Modal>
  );
};

export default ShapesModal;
