import { emitDeleteObject } from "../../socket";
import { Layout, Tooltip, Button, Modal, Input, Upload, message } from "antd";
import { fabric } from "fabric";
import {
  SelectOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  FormOutlined,
  LineHeightOutlined,
  EditOutlined,
  CommentOutlined,
  BorderOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Oval from "../../assets/oval.png";
import Shapes from '../../assets/shapes.jpg'
import Rectangle from "../../assets/rectangle.png";
import Ellipse from "../../assets/ellipse.png";
import Triangle from "../../assets/triangle.png";
import textInShape from '../../assets/text_box-512.png'
import Square from "../../assets/square.png";
import RightAngleTriangle from "../../assets/right-triangle.png";
import Cylinder from "../../assets/cylinder.png";
import Diamond from '../../assets/diamond.png';
import RightArrow from '../../assets/right-arrow.png';
import React, {useEffect, useState } from "react";
import ShapesModal from "./ShapesModal";
import PenOptions from "./PenToolMenu";
import { useCanvas } from "../../context/canvasContext";
import TextMenu from "./TextMenu";
import { useParams } from "react-router-dom";
import useMousePosition from "../../hooks/useMousePosition";
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { ReactComponent as Eraser } from "../../assets/eraser.svg";
import { useDocument } from "../../context/documentInfoContex";

const Sidebar = () => {
  const isMobile = window.screen.width < 768 ;
  const isSmallMobile = window.screen.width < 390 ;
  const isTablet = window.screen.width < 1200 && window.screen.width > 768;



  // const {x,y}=useMousePosition()
  const [isShapeModalVisible, setIsShapeModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPenOptionsVisible, setPenOptionsVisible] = useState(false);
  const [isTextMenuVisible, setTextMenuVisible] = useState(false);
  const [isFreeDrawingModeOn, setFreeDrawingModeOn] = useState(false);
  const [penMenuOptions, setPenMenuOptions] = useState();
  const [activeTextObject, setActiveObject] = useState({});
  const [isTextMode, setIsTextMode] = useState(false);
  const { canvasRef, createIText, createImage, documentData,addTextToShape,saveStateToUndoStack } = useCanvas();
  const { isReadOnly } = useDocument();
  //textColor, setTextColor, textSize, setTextSize, textFont, setTextFont, textAlign, setTextAlign, textStyle, setTextStyle
  const [textColor, setTextColor] = useState();
  const [textSize, setTextSize] = useState();
  const [textFont, setTextFont] = useState();
  const [textAlign, setTextAlign] = useState();
  const [textStyle, setTextStyle] = useState();
  let undoStack = [];
  let redoStack = [];
  const { id } = useParams();

  const shapes = [
    { name: "circle", image: <img src={Oval} width={40} height={40} /> },
    {
      name: "rectangle",
      image: <img src={Rectangle} width={40} height={40} />,
    },
    { name: "ellipse", image: <img src={Ellipse} width={40} height={40} /> },
    { name: "triangle", image: <img src={Triangle} width={40} height={40} /> },

    // Commented Below because Undo Redo are not funtioning on them: Serialization and Deserialization is NOT being done correctly because shapes are customized

    // { name: "square", image: <img src={Square} width={40} height={40} /> },
    // { name: "cylinder", image: <img src={Cylinder} width={40} height={40} /> },
    // { name: "right-angle-triangle", image: <img src={RightAngleTriangle} width={40} height={40} /> },
    // { name: "diamond", image: <img src={Diamond} width={40} height={40} /> },
    // { name: "arrow", image: <img src={RightArrow} width={40} height={40} /> },
  ];

  // * This useEffect set the new incoming properties of Pen 
  useEffect(()=>{
    applyChangesToPen(penMenuOptions)
  },[penMenuOptions])

  const handleTextMode = () => {
    setIsTextMode(!isTextMode);
    canvasRef.current.discardActiveObject();
    canvasRef.current.renderAll();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShapeModalShow = () => {
    handleEraserMouseUp()
    setIsShapeModalVisible(true);
    setFreeDrawingModeOn(false);
    setTextMenuVisible(false);
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);
  };

  const handleShapeModalHide = () => {
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    setTextMenuVisible(false);
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);
  };

  const handleUploadModalShow = () => {
    handleEraserMouseUp()
    setIsUploadModalVisible(true);
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    setTextMenuVisible(false);
    setPenOptionsVisible(false);
  };

  const handleUploadModalHide = () => {
    setIsUploadModalVisible(false);
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    setTextMenuVisible(false);
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);
  };

  const handleSelectionClick = () => {
    handleEraserMouseUp()
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    setTextMenuVisible(false);
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);
    canvasRef.current.isDrawingMode = false;
  }

  const setDrawingMode = () => {
    canvasRef.current.isDrawingMode = isFreeDrawingModeOn;
  };

  const handlePenClick = () => {
    handleEraserMouseUp()
    setPenOptionsVisible(true);
    setTextMenuVisible(false);
    setFreeDrawingModeOn(!isFreeDrawingModeOn);
    if (isFreeDrawingModeOn) {
      canvasRef.current.isDrawingMode = true;
    } else {
      canvasRef.current.isDrawingMode = true;
    }

    applyChangesToPen({
      width: 5,
      color: "#000000",
      opacity: 1,
      smoothing: false,
      straighten: false,
    });
  };

  const applyChangesToPen = (obj) => {
    setPenMenuOptions(obj);
    if (penMenuOptions) {
      Object.keys(penMenuOptions).forEach((key) => {
        if (key === 'lineStyle') {
          // Handle lineStyle property separately
          if (penMenuOptions[key] === 'dashed') {
            canvasRef.current.freeDrawingBrush.strokeDashArray = [18,24,18]; // Customize the dash pattern
          } else if (penMenuOptions[key] === 'dotted') {
            canvasRef.current.freeDrawingBrush.strokeDashArray = [8, 2, 8]; // Customize the dot pattern
          } else if (penMenuOptions[key] === 'solid') {
            canvasRef.current.freeDrawingBrush.strokeDashArray = null; // Reset dash pattern for solid line
          }
        } else {
          // Apply other pen properties
          canvasRef.current.freeDrawingBrush[key] = penMenuOptions[key];
        }
      });
      canvasRef.current.contextContainer.globalAlpha = penMenuOptions.opacity;
      canvasRef.current.renderAll();
    }
  };

  const handleTextClick = () => {
    handleEraserMouseUp()
    setTextMenuVisible(true);
    setIsTextMode(true);
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    canvasRef.current.isDrawingMode = false;
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);
    createIText(id, {
      setTextAlign,
      setTextColor,
      setTextFont,
      setTextSize,
      setTextStyle,
    });
  };
/**
 * Handles the text-related actions within a shape on the canvas.
 * - Checks if there is an active object on the canvas.
 * - If there is no active object, it displays an error message.
 * - If there is an active object:
 *   - Makes the text menu visible.
 *   - Sets the application in text mode.
 *   - Hides the shape modal.
 *   - Disables free drawing mode.
 *   - Hides the upload modal.
 *   - Hides the pen options.
 * - Adds text properties (align, color, font, size, style) to the active shape.
 */
const handleTextInshape = () => {
  // Get the currently active object on the canvas
  const activeObjects = canvasRef.current.getActiveObject();
  
  // console.log("From Sidebar", activeObjects);

  // Check if there is no active object
  if (!activeObjects) {
    message.error("Create a shape first");
  } else {
    // Make text-related controls visible and set the application in text mode
    setTextMenuVisible(true);
    setIsTextMode(true);
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);

    // Add text properties (align, color, font, size, style) to the active shape
    addTextToShape(id, {
      setTextAlign,
      setTextColor,
      setTextFont,
      setTextSize,
      setTextStyle,
    });
  }
}
 

  document.addEventListener("keydown", function (event) {
    // Check if the delete key is pressed (key code 46)
    if (event.keyCode === 46 ) {
      // Get the currently selected objects on the canvas
      let activeObjects = canvasRef.current.getActiveObjects();
      if (
        canvasRef.current.getActiveObject() &&
        canvasRef.current.getActiveObject().type === "i-text" &&
        canvasRef.current.isEditing
      ) {
        return; // Exit the function if text is being edited
      }
      // If there are any selected objects, delete them
      if (activeObjects) {
        activeObjects.forEach(function (object) {
          canvasRef.current.remove(object);
          saveStateToUndoStack()
          emitDeleteObject(object.id)
        });
        canvasRef.current.discardActiveObject().renderAll();
      }
    }
    //Shapes (S)
    // if (event.ctrlKey && event.key === "s" ||event.key === "S" ) {
    //   setIsShapeModalVisible(true);
    // }
    
    // Check if the "T" key is pressed (key code 84)
    if (event.keyCode === 84) {
    }

    // Check if the "U" key is pressed (key code 85)
    if (event.keyCode === 85) {
      // Disable selection mode and enable drawing mode
    }

    // Check if the "P" key is pressed (key code 80)
    // if (event.keyCode === 80) {
    //   setPenMenuOptions({
    //     width: 5,
    //     color: "#000000",
    //     opacity: 1,
    //     smoothing: false,
    //     straighten: false,
    //   })
    //   handlePenClick();
    // }
   
  });
  //Event Handler created to recieve the image object and pass it to createImage function that's imported from canvasContext.
  const handleImageUpload = (ImageInfo) => {
    // console.log("ImageInfo : ", ImageInfo) // Upload Component from Antd is passed 
    // debugger
    if (ImageInfo.file.status === 'uploading') {
      const imageURL = URL.createObjectURL(ImageInfo.file.originFileObj); //creating URL of Object information in originFileObj
      // console.log("IMG_URL ", imageURL);
      // // debugger
      // console.log("Room ID from AsideBar file : ",id)
      createImage(imageURL,id);           //Passing the Image URL to the function
      handleUploadModalHide();
      message.success(`${ImageInfo.file.name} uploaded successfully.`);   //message Toast will be shown once image is uploaded
    }
    else if (ImageInfo.file.status === 'error')   // Handled error with else if
    {
      message.error(`${ImageInfo.file.name} upload failed.`);
      // console.log('Error');
    }
  };
  const messageOnUpload = () => {
    message.success("Image uploaded successfully.");   //message Toast will be shown once image is uploaded

  }
  // const handleImageUploadProgress = ({ percent }, file) => {
  //   console.log(`Uploading ${file.name}: ${percent.toFixed(2)}%`);     //Commented Code : Created function to track upload progress for Testing Purpose
  // }


  // Function: handleEraser
  // Description: Activates the eraser tool on the Fabric.js canvas, allowing users to erase objects.
  // Assumes canvasRef is a reference to the Fabric.js canvas instance.
  const handleEraser = () => {
    const canvas = canvasRef.current;

    // Set eraser mode to true
    canvas.isErasing = true;

    // Hide other modals and set relevant options
    setIsShapeModalVisible(false);
    setFreeDrawingModeOn(false);
    setTextMenuVisible(false);
    setIsUploadModalVisible(false);
    setPenOptionsVisible(false);
    canvasRef.current.isDrawingMode = false;

    // Change cursor to the eraser icon
    canvas.setCursor(`url(${Eraser}), auto`);

    // Add event listener for mouse move to handle eraser functionality
    canvas.on('mouse:down', handleEraserMouseMove);
  };

  // Function: handleEraserMouseMove
  // Description: Handles the eraser functionality during mouse movement on the canvas.
  const handleEraserMouseMove = (event) => {
    const canvas = canvasRef.current;

    if (!canvas.isErasing) {
      return;
    }

    // Get current pointer coordinates
    const { x, y } = event.pointer;

    // Create a rectangular path representing the eraser with an eraser icon background
    const eraserPath = new fabric.Rect({
      left: x, // Adjust position based on eraser size
      top: y, // Adjust position based on eraser size
      width: 2, // Adjust width based on eraser size
      height: 5, // Adjust height based on eraser size
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      fill: new fabric.Pattern({
        source: Eraser,
        repeat: 'no-repeat',
      }),
    });
    canvasRef.current.discardActiveObject();

    // Iterate through objects, make them slightly transparent before removing
    canvas.forEachObject((object) => {
      if (eraserPath.intersectsWithObject(object)) {
        // Set opacity to 0.5 (adjust as needed)
        object.set('opacity', 0.5);
        canvas.renderAll();
        // Delay removal to make opacity change visible
        setTimeout(() => {
          canvas.remove(object);
          let removedObjID = object.id;
          emitDeleteObject(removedObjID);
        }, 300); // Adjust delay duration if needed

        saveStateToUndoStack();
      }
    });

    // Render canvas to apply changes
    canvas.renderAll();
    return false;
  };

  // Function: handleEraserMouseUp
  // Description: Resets eraser mode and removes event listeners on mouse up.
  const handleEraserMouseUp = () => {
    const canvas = canvasRef.current;
    canvas.isErasing = false;
    canvas.off('mouse:down', handleEraserMouseMove);
    canvas.off('mouse:up', handleEraserMouseUp);
  };



  return (
   !isReadOnly &&
    <div
      style={{
        width: isMobile?'230px':"80px",
        height: isMobile?'50px':"100%",
        backgroundColor: "#fff",
        boxShadow: "rgb(101 92 92 / 50%) 0px 3px 8px",
        borderRadius: "5px",
        padding: 10,
        position:isMobile?'absolute':"relative",
        left:isMobile?"23px":'',
        top:isMobile?isSmallMobile?'600px':'770px':'',
        zIndex:1
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile?'row':"column",
          justifyContent: "center",
          alignItems: "center",
          height: isMobile?'50px':"100%",
          gap:isMobile?'10px':'',

        }}
      >
        <Tooltip title="Selection Tool (V)" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<SelectOutlined />}
            onClick={handleSelectionClick}
          />
        </Tooltip>

        <Tooltip title="Text Tool (T)" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<FontSizeOutlined />}
            onClick={handleTextClick}
          />
        </Tooltip>
        {isTextMenuVisible && (
          <div
            style={{ marginLeft: 300, marginTop: 150, position: "absolute" }}
          >
            <TextMenu
              textAlign={textAlign}
              textColor={textColor}
              textFont={textFont}
              textSize={textSize}
              textStyle={textStyle}
              setTextAlign={setTextAlign}
              setTextColor={setTextColor}
              setTextFont={setTextFont}
              setTextSize={setTextSize}
              setTextStyle={setTextStyle}
              setTextMenuVisible={setTextMenuVisible}
              isTextMenuVisible={isTextMenuVisible}
            />
          </div>
        )}
          {/* /**
                    * Button component for handling text within shapes.
                    * - Checks if there are active shapes of supported types (rectangle, circle, triangle, ellipse).
                    * - Displays a button with a tooltip for adding text to the active shape.
                    * - When clicked, it invokes the handleTextInshape function.
                    */ }



        {/* <Tooltip title="Sticky Notes Tool" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<HighlightOutlined />}
          />
        </Tooltip> */}

        <Tooltip title="Shapes Tool (S)" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<CategoryOutlinedIcon sx={{ fontSize: 22 }}/>}
            onClick={handleShapeModalShow}
          />
        </Tooltip>

        {/* <Tooltip title="Connection Line Tool" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<LineHeightOutlined />}
          />
        </Tooltip> */}

        <Tooltip title="Pen Tool (P)" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<EditOutlined />}
            onClick={handlePenClick}
          />
        </Tooltip>
        {isPenOptionsVisible && (
          <div
            style={{ marginLeft: 350, marginTop: 200, position: "absolute" }}
          >
            <PenOptions
              penMenuOptions={penMenuOptions}
              applyChangesToPen={applyChangesToPen}
              setPenOptionsVisible={setPenOptionsVisible}
              isPenOptionsVisible={isPenOptionsVisible}
              setFreeDrawingModeOn={setFreeDrawingModeOn}
              isFreeDrawingModeOn={isFreeDrawingModeOn}
            />
          </div>
        )}

        {/* <Tooltip title="Comment Tool" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<CommentOutlined />}
          />
        </Tooltip> */}

        {/* <Tooltip title="Frame Tool" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<BorderOuterOutlined />}
          />
        </Tooltip> */}

          <Tooltip title="Eraser (E)" placement="right">
            <Button
              type="ghost"
              shape="circle"
              style={{ marginBottom: "20px" }}
              icon={<Eraser style={{width:16,height:16}}/>}
              onClick={handleEraser}
            />
          </Tooltip>

        <Tooltip title="Upload Tool (U)" placement="right">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<UploadOutlined />}
            onClick={handleUploadModalShow}
          />
        </Tooltip>
      </div>

      <ShapesModal
        shapes={shapes}
        visible={isShapeModalVisible}
        onCancel={handleShapeModalHide}
      />

      <Modal
        title="Upload"
        visible={isUploadModalVisible}
        onCancel={handleUploadModalHide}
        footer={null}
      >
        {/* using Upload from Antd for prompting Upload and selecting files */}
        <Upload
          accept="image/*"
          customRequest={() => { }}
          showUploadList={false}
          multiple
          onChange={handleImageUpload}
        // onProgress={handleImageUploadProgress}  //Commented Code : Created OnProgress to track progress of upload
        >
          <Button type="primary" block style={{ marginBottom: "10px" }}>

            Upload from computer
          </Button>
        </Upload>
        {/* Upload Dragger used to insert area where user will drop the image and canvas will take it */}

        <Upload.Dragger
          accept="image/*"
          customRequest={() => { }}
          showUploadList={false}
          // beforeUpload={() => false} // Prevent direct upload

          multiple
          // onChange={handleImageUpload}         // Commented Code: //same Logic is used for taking the dropped image from User as that of used in Upload from Computer
          onChange={() => {
            messageOnUpload();
            handleUploadModalHide();
          }

          }
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Drag & drop an image here</p>


        </Upload.Dragger>




      </Modal>
    </div>
  );
};

export default Sidebar;
