import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useCanvas } from "../../context/canvasContext";

const Canvas = () => {
  const {zoomIn, zoomOut, resetZoom, bgColor} = useCanvas();
  const isTablet = window.screen.width < 1200 && window.screen.width > 768;
  const isMobile = window.screen.width < 768 ;


  return (
    <div  
    style={{
      width:isMobile?window.screen.width-30:800,
      position: "absolute",
      left: "50%",
      top: isTablet?'400px':"50%",
      "webkit-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      backgroundColor: '#fff',
      boxShadow: 'rgb(101 92 92 / 50%) 0px 3px 8px',
      borderRadius: '5px',
    }}>
      {/* <div className="canvas-btn-container">
        <Button type="default" icon={<ZoomInOutlined />} size="medium" onClick={zoomIn} style={{zIndex: 10}} />
        <Button type="default" size="medium" onClick={resetZoom} style={{zIndex: 10}} >Reset</Button>
        <Button type="default" icon={<ZoomOutOutlined />} size="medium" onClick={zoomOut} style={{zIndex: 10}} />
      </div> */}
      <canvas id="canvas" width="800" height="500"/>
    </div>
  );
};

export default Canvas;
