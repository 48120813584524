import React from 'react'
import { Skeleton, Space, Layout, Avatar } from 'antd';

const Loader= ()=> {
  return (
    <>
    <div
    style={{
      width: "350px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      borderRadius: '5px',
      padding: 20,
      position:'absolute',
      top:"20px",
      left:"25px"
    }}
  >
    <Space>
    <Skeleton.Image active style={{ height: '35px', width: '35px', marginTop:'12px' }} />
    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '12px'}} />
    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '12px'}} />
    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '12px'}} />
    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '12px'}} />
    </Space>
</div>
  <div
  style={{
    width: "80px",
    height: "280px",
    backgroundColor: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "5px",
    padding: 10,
    position:'absolute',
    top:"285px",
    left:"20px"
 }}
>
 <div
    stle={{
      display: "lex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
                <Space style={{display:'flex',flexDirection:'column'}}>
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '8px'}} />
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '8px'}} />
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '8px'}} />
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '8px'}} />
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '8px'}} />
                    </Space>
    </div>
    </div>
    
    <div  style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      "webkit-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      // backgroundColor: bgColor,
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      borderRadius: '5px',
    }}>
                    <Skeleton.Button active style={{ height: '500px', width: '800px' }} />

    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '80px',
        marginTop: 20,
        padding: 10,
        height: '100px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        borderRadius: '5px',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: '#fff',
        position:'absolute',
        top:'570px',
        left:"20px"

      }}
    >
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '12px'}} />
                    <Skeleton.Button active style={{ height: '28px', width: '40px' ,margin: '12px'}} />
      </div>
    
</>

)
    }

export default Loader
