import { Avatar, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import './members.css';
import { useCanvas } from '../../context/canvasContext';
import { renderMouse } from '../../socket';

const DocumentMembers = () => {
    const { documentData, userInfo } = useCanvas();
    const [onlineUsers, setOnlineUsers] = useState([]);
    const colorOptions = [];

    const getInitials = (name) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        renderMouse(setOnlineUsers);
        // connectedClients(setConnectedSocketID);
    }, []);

    const getRandomColor = () => {
        const newColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
        if (newColor !== '#CF9FFF') {
            if (!colorOptions.includes(newColor)) {
                colorOptions.push(newColor);
                return newColor;
            }
        }
    };

    return (
        <Avatar.Group
            maxCount={4}
            maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
            }}
        >
            {documentData?.members?.length >= 1 && documentData.members.map((member, ind) => {
                if (userInfo.userId !== member.id) {
                    const backgroundColor = getRandomColor();
                    return (
                        <Badge
                            key={ind}
                            offset={["-6%", "95%"]}
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "green"
                            }}
                            //Users Filtered to see if Mouse is coming from a User and set it Tagged Online with Dot
                            dot={onlineUsers.some(item=> item.userName === member.name)}
                        >
                            <Avatar
                                src={member.image}
                                style={{
                                    backgroundColor: backgroundColor,
                                    marginLeft: '-4px',                                    marginLeft: '-5px',
                                    width:'30px',
                                    height:'30px',
                                    lineHeight:'30px',
                                    fontSize:'12px'
                                }}
                            >
                                {getInitials(member.name)}
                            </Avatar>
                        </Badge>
                    );
                }
            })}
        </Avatar.Group >
    );
}

export default DocumentMembers;
