import React, { useState } from 'react'
// import {} from 'antd'
import {ReactComponent as ModesIcon} from '../assets/modes.svg';
import {ReactComponent as DarkIcon} from '../assets/Dark-Mode.svg';
import { useCanvas } from '../context/canvasContext';
import { dark } from '@mui/material/styles/createPalette';
import { Tooltip } from 'antd';




function Modes() {
  const isMobile =window.screen.width<768

    const [darkMode,setDarkMode]=useState(false)
    const {setMode}=useCanvas()
    setMode(darkMode)
    // console.log('darkMode : Out ',darkMode)

  return (
    <div><Tooltip title={!darkMode? "Enable Dark Mode":"Enable Light Mode"} placement='top'>
            <div onClick={()=>{
            setDarkMode(!darkMode)
            }} style={{ position: "", right: "10px", marginTop: "3px", cursor: "pointer", zIndex: 1,opacity:.95 ,}} >
              {darkMode==true ?
              <ModesIcon style={{width:isMobile?'20px': "26px",height:isMobile?'20px': "26px" }}/>:
              <DarkIcon style={{width:isMobile?'20px': "28px",height:isMobile?'20px': "28px" }}/>}

            </div>
            </Tooltip>
    </div>
  )
}

export default Modes