import React, { useEffect, useState } from 'react'
import savingIcon from '../../assets/Saving.png';
import savedIcon from '../../assets/saved.png';
import { useCanvas } from '../../context/canvasContext'
import './SavingSaved.css'
import { useDocument } from '../../context/documentInfoContex';

const SavingSaved = () => {
    // const{lastChange}=useCanvas();
    const {savingData} = useDocument();
    const [isSave, setIsSave] = useState();

    // useEffect(()=>{
    //     setIsSave(lastChange())

    // },[lastChange()])

    return (
        // <div>
        //     {lastChange() && <div className="SavingSaved">
        //         {lastChange() == 'Saving...' ? (
        //             <img src={savingIcon} alt='Private documnet' style={{ width: '15px', height: '15px', marginRight: 10, marginTop: 4 }} />
        //         ) : <img src={savedIcon} alt='Private documnet' style={{ width: '20px', height: '20px', marginRight: 10 }} />
        //         } <p>
        //             {lastChange()}
        //         </p>
        //     </div>}
        // </div>
        <div className='SavingSaved'>
            {savingData.isSaving === 'Saving...' ? (
                <img src={savingIcon} alt='Private document' style={{ width: '22px' }} />
            ) : (
                <img src={savedIcon} alt='Private document' style={{ width: '24px' }} />
            )}
            <span>
                {savingData.isSaving}
            </span>
            <span>
                {savingData.lastSavedDateTime}
                {savingData.isSaving === 'Saved' && `, ${savingData.fileSize}`}
            </span>
        </div>
    )
}

export default SavingSaved;
