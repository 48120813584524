import React from "react";
import { ShareModal } from "./ShareModal/ShareModal";
import { UserAvatar } from "./Avatar/UserAvatar";
import { useCanvas } from "../context/canvasContext";
import privateIcon from '../assets/lock.svg';
import publicIcon from '../assets/world.svg';
import DocumentMembers from "./Members/members";
import Modes from './modes.js'

const UserDetails = () => {
  const { documentData, userInfo } = useCanvas();
  return (
    <div
      style={{
        width: "fit-content",
        height: "70px",
        display: "flex",
        alignItems: "center",
        gap: 14,
        backgroundColor: "inherit",
        borderRadius: '5px',
        marginRight: '20px',
        paddingBottom:'17px',
        zIndex:1
      }}
    >
      
      <DocumentMembers/>
      {documentData.createBy == userInfo.userId && (
        <>
        <ShareModal />

        </>
      )}
      <Modes/>
      <UserAvatar />
    </div>
  );
};

export default UserDetails;
