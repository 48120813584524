import { useState } from 'react';
import { Collapse, Slider, InputNumber, Row, Col, Switch ,Tooltip,Button} from 'antd';
import { useCanvas } from '../../context/canvasContext';
import { ReactComponent as Dashed } from '../../assets/dashed.svg'
import { ReactComponent as Dotted } from '../../assets/dotted_1.svg'
import { ReactComponent as SolidLine } from '../../assets/line.svg'


const { Panel } = Collapse;

const PenOptions = ({penMenuOptions, applyChangesToPen,setPenOptionsVisible,isPenOptionsVisible,setFreeDrawingModeOn,IsFreeDrawingModeOn}) => {
  const {canvasRef}=useCanvas()
  const isMobile=window.screen.width<768

  const handleWidthChange = (value) => {
    console.log(value)
    console.log(penMenuOptions,"penMenu")
    applyChangesToPen({ ...penMenuOptions, width: value });
  };

  const handleOpacityChange = (value) => {
    applyChangesToPen({ ...penMenuOptions, opacity: value });
  };

  const handleColorChange = (value) => {
    applyChangesToPen({ ...penMenuOptions, color: value });
  };

  const handleDashLine = () => {
    applyChangesToPen({ ...penMenuOptions, lineStyle:'dashed' });

  };

  const handleDottedLine = () => {
    applyChangesToPen({ ...penMenuOptions, lineStyle:'dotted' });

  };
  const handleSolidLine = () => {
    applyChangesToPen({ ...penMenuOptions, lineStyle:'solid' });

  };
  const handleSmoothingChange = (value) => {
    applyChangesToPen({ ...penMenuOptions, smoothing: value });
  };

  const handleStraightenChange = (value) => {
    applyChangesToPen({ ...penMenuOptions, straighten: value });
  };

  const handleCollapseChange = (activeKeys) => {
    // Check if '1' (key of the "Text Properties" Panel) is in the activeKeys array
    const isPenOptionsVisible = activeKeys.includes('1');
    setPenOptionsVisible(isPenOptionsVisible);
    canvasRef.current.isDrawingMode = false;

  };

  return (
    <div style={{width:isMobile?'155px':'168px',position:isMobile?'absolute':'',top:isMobile?'-290px':'',right:isMobile?"40px":'',boxShadow: "rgb(101 92 92 / 50%) 0px 3px 8px",
    borderRadius: "5px",}}>
      <Collapse
        bordered={true}
        defaultActiveKey={['1']}
        expandIconPosition="right"
      activeKey={isPenOptionsVisible ? ['1'] : []} // Set activeKey based on the visibility state
      onChange={handleCollapseChange}
      >
        <Panel style={{fontSize:isMobile?'11px':'13px'}} header="Pen Controls" key="1">
          <Row style={{marginBottom: 20, width: 180,height:'40px' }}>
            <Col span={8} style={{marginBottom: 20,marginRight:isMobile?'6px':'14px',marginLeft:'10px'}}>
              <label style={{fontSize:12}}>Width:</label>
              <Slider
                min={1}
                max={10}
                onChange={handleWidthChange}
                value={penMenuOptions.width}
              />
            </Col>
            {/* <Col span={8} style={{marginBottom: 20,}}>
              <label>Opacity:</label>
              <Slider
                min={0}
                max={1}
                step={0.1}
                onChange={handleOpacityChange}
                value={penMenuOptions.opacity}
              />
            </Col> */}
          
            <Tooltip title="Drag to Select Color" placement="right">
            <Col span={8} >
              <label style={{fontSize:12}}>Color:</label>
              <input style={{}}
                type="color"
                value={penMenuOptions.color}
                onChange={(e) => handleColorChange(e.target.value)}
              />
            </Col>
            </Tooltip>
          </Row>
          <Row style={{display:'flex',justifyContent:'space-around',height:25}}>
          <Tooltip title="Dashed Line" placement="top">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "0px" }}
            icon={<Dashed style={{width:20,height:25}}/>}
            onClick={handleDashLine}
          />
        </Tooltip>
        <Tooltip title="Dotted Line" placement="top">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<Dotted style={{marginTop:1,width:20,height:24}}/>}
            onClick={(e)=> handleDottedLine(e)}
          />
        </Tooltip>
        <Tooltip title="Solid Line" placement="top">
          <Button
            type="ghost"
            shape="circle"
            style={{ marginBottom: "20px" }}
            icon={<SolidLine style={{width:25,height:25}}/>}
            onClick={handleSolidLine}
          />
        </Tooltip>
        </Row> 
          {/* <Row >
            <Col span={12}>
              <label>Smoothing:</label>
              <Switch
                checked={penMenuOptions.smoothing}
                onChange={(checked) => handleSmoothingChange(checked)}
                style={{marginRight: 20,}}
              />
            </Col>
            <Col span={12}>
              <label>Straighten:</label>
              <Switch
                checked={penMenuOptions.straighten}
                onChange={(checked) => handleStraightenChange(checked)}
              />
            </Col>
          </Row> */}
        </Panel>
      </Collapse>
    </div>
  );
};

export default PenOptions;
