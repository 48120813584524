import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import useMousePosition from "./hooks/useMousePosition";
import Toolbar from "./components/Toolbar";
import socket, {
  addTotalClients,
  connectedClients,
  emitMousePositions,
  removeTotalClients,
  renderMouse,
} from "./socket";
import Board from "./components/Board";
import IconBar from "./components/iconbar";
import Header from "./components/Header/Header";
import Undo from "./components/Undo";
import Title from "./components/Title";
import UserDetails from "./components/UserDetails";
import "./index.css";
import Sidebar from "./components/AntSideBar/AntSideBar";
import UndoRedoToolbar from "./components/Undo";
import BoardHeader from "./components/Title";
import { ChatBox } from "../src/components/ChatBox/ChatBox";
import { AuthenticateDocument } from './utils/AuthenticateDocument';
import MouseContainer from "./components/mousecontainer";

function App() {
  const isTablet = window.screen.width < 1200 && window.screen.width > 768;
  const isMobile = window.screen.width < 768 ;

  const url_string = window.location.href; //window.location.href
  const url = new URL(url_string);
  const hideMenu = url.searchParams.get("hideMenu");
  const {id}=useParams(); 

  const [isAuthenticating, setIsAuthenticating] = useState(true)
  if (isAuthenticating) { // isAuthenticating
    return <AuthenticateDocument setIsLoading={setIsAuthenticating} />
  }
  return (
    <>
      <Router>
        <div>
          <Switch>
            <Route exact path="/:id">
              {/* <BoardNav /> */}
              {/* <AntNavBar /> */}
              {/* <Header /> */}
              <div
                style={{
                  margin: 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <BoardHeader isMobile={isMobile} />
                <UserDetails />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: isTablet?'900px':'80vh'
                }}
              >
                <div style={{ padding: 20,position:isTablet?"absolute":'',top:isTablet?'110px':"" }}>
                  {!hideMenu && <Sidebar />}

                  {!hideMenu && <UndoRedoToolbar />}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                  {/* <AntSideBar /> */}
                  <Board />
                  {/* <AntZoomHandler /> */}
                </div>
              </div>
            </Route>
          </Switch>
          <ChatBox id={id}/>
          <MouseContainer id={id}/>
        </div>
      </Router>
    </>
  );
}

export default App;
