import classes from './UserAvatar.module.css';
import { useState } from "react";
import { Badge, Image } from 'antd';
import { useCanvas } from '../../context/canvasContext';
import { useDocument } from '../../context/documentInfoContex';

export const UserAvatar = () => {
  const isMobile=window.screen.width < 768
  const { userInfo,documentData } = useCanvas();
  const { isReadOnly } = useDocument();
  const right={
    right:!isReadOnly?"Collaborator":"Reader"
  }
  const role={
    role:documentData.createBy == userInfo.userId?"Creator":"Member"
  }
  const [modalOpen, setModalOpen] = useState(false);

  const getInitials = () => {
    const names = userInfo.name.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  const renderProfileImage = (isDetailModalOpen) => {
    if (userInfo.image) {
      return (
        <Badge
          style={{
            left: isDetailModalOpen ? '40px' : '22px',
            bottom: '-2px',
            width: isDetailModalOpen ? '12px' : '8px',
            height: isDetailModalOpen ? '12px' : '8px',
            top: 'auto',
            background: 'green',
            margin: '0 auto',
            display: 'block'
          }}
          dot={true}
        >
          <Image
            src={userInfo.image}
            width={isMobile ? '30px' : "35px"}
            height={isMobile ? '30px' : "35px"}
            onClick={() => setModalOpen(true)}
            style={{ borderRadius: "50%" }}
            preview={isDetailModalOpen ? isDetailModalOpen : false}
          />
        </Badge>
      );
    } else {
      return (
        <Badge
          style={{
            left: isDetailModalOpen ? '32px' : '22px',
            bottom: '-2px',
            width: isDetailModalOpen ? '10px' : '8px',
            height: isDetailModalOpen ? '10px' : '8px',
            top: 'auto',
            background: 'green',
            margin: '0 auto',
            display: 'block'
          }}
          dot={true}
        >
          <div className={classes["no-profileImage-div"]} onClick={() => setModalOpen(true)}
            style={{
              width: isDetailModalOpen ? '60px' : '35px',
              height: isDetailModalOpen ? '60px' : '35px',
              fontSize: isDetailModalOpen ? '26px' : '16px'
            }}
          >
            {getInitials()}
          </div>
        </Badge>
      );
    }
  };

  return (
    <div
      onMouseLeave={() => setModalOpen(false)}
      onMouseEnter={() => setModalOpen(true)}
    >
      {renderProfileImage(false)}
      {modalOpen && (
        <div className={classes['custom-modal-container']}>
          <div className={classes['modal-profile-image']}>
            {renderProfileImage(true)}
          </div>
          {/* <span style={{fontWeight:'600', fontSize:'26px'}}>{userInfo.name}</span>
          <span style={{fontWeight:'600', fontSize:'16px', marginTop:'-10px'}}>{userInfo.designation}</span>
          <span style={{marginTop:'2px', color:'blue'}}className={classes['user-detail-text']}>{userInfo.email}</span> */}
          <div className={classes['user-detail-container']}>
            <div>
              <span className={classes['user-detail-label']}>Name: </span>
              <span className={classes['user-detail-text']}>{userInfo.name}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Email: </span>
              <span className={classes['user-detail-text']}>{userInfo.email}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Rights: </span>
              <span className={classes['user-detail-text']}>{right.right}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Role: </span>
              <span className={classes['user-detail-text']}>{role.role}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
