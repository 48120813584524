import React, { useContext, useState, useRef } from "react";
import { fabric } from "fabric";
import { v1 as uuid } from "uuid";
import { emitAdd, emitClearCanvas, emitImageData, emitText,emitBackgroundColorChange, onBackgroundColorChange,emitCanvasData,emitDeleteObject,emitClonedObj,emitShapeColorChange,emitSendToBack,emitBringToFront,emitObjID,emitStrokeColorChange,emitUnGroup} from "../socket";
import "fabric-history";
import { useParams } from "react-router";
import { ContactsOutlined, CopyOutlined} from "@ant-design/icons";
import bringToFrontImg from '../assets/bringToFron.png'
import SendtoBackImg from '../assets/sendToback.png'
import deleteIcon from '../assets/DeleteButton.jpg'
import copyIcon from '../assets/copyIcon-2.png'
import groupIcon from '../assets/groupIcon.png'
import ungroup from '../assets/ungroup.png'
import ungroupWhite from '../assets/ungroup-white.png'
import SendBackWhite from '../assets/sendBack-white.png'
import groupWhite from '../assets/group-white.png'
import duplicateWhite from '../assets/duplicate-white.png'
import deleteWhite from '../assets/delete-white.png'
import bringFrontWhite from '../assets/bringFront-white.png'
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
} from 'darkreader';


const CanvasContext = React.createContext();

export const CanvasProvider = ({ children }) => {
  const isMobile=window.screen.width<768
  const [isSnapToGrid, setSnapToGrid] = useState(false);
  const [isChangeSomething, setIsChangeSomething] = useState(false);
  const lastChangeRef = useRef(null);
  let undoStack = [];
  let redoStack = [];
  let reloadStack=[];
  const canvasRef = useRef(null);
  let draw = false,
    x1,
    readOnly,
    y1,
    x2,
    y2,
    pausePanning,
    zoomStartScale,
    currentX,
    currentY,
    xChange,
    lastX,
    yChange,
    lastY;

  // let SCALE_FACTOR = 1.3; //on clicking zoom, enlarge canvas in 30%. TBD
  // let zoomMax = 6;   //TBD
  
  //Below Variable created for savind id that comes when PrepareCanvas executes (on Loading or Opening Board Document.) this Id is Document ID taken from params in component where prepareCanvas is called
  let DocID;

  const prepareCanvas = (id) => {
    
    //DocID initialized as id coming from params
    DocID=id;
    //Render initial canvas with the width as window.innerWidth, window.innerHeight.
    // And background color as a variant of grey.

    let canvas;
    canvas = new fabric.Canvas("canvas", {
      width: isMobile?window.screen.width-30:800,
      height: 500,
      // backgroundColor: '#fff',
      fireRightClick: true,  // <-- enable firing of right click events
      fireMiddleClick: true, // <-- enable firing of middle click events
      stopContextMenu: true,
    });

    //Initializing base values for the drawing mode and the drawing brush.
    canvas.allowTouchScrolling = true;

    // canvas.renderOnAddRemove = false;

    canvas.isDrawingMode = false;
    canvas.freeDrawingBrush.width = 10;
    //    canvas.selectable = false;
    //    canvas.selection= false;
    canvasRef.current = canvas;
    if (id) {
      let cvData = canvas.toJSON(["selectable", "id", "type"]);
      emitCanvasData(id, cvData);
    }
    canvas.on({
      "touch:gesture": function (e) {
        if (e.e.touches && e.e.touches.length == 2) {
          // console.log("i guess this works?");
          pausePanning = true;
          var point = new fabric.Point(e.self.x, e.self.y);
          if (e.self.state == "start") {
            zoomStartScale = canvas.getZoom();
          }
          var delta = zoomStartScale * e.self.scale;
          canvas.zoomToPoint(point, delta);
          pausePanning = false;
        }
      },
      "object:selected": function () {
        // console.log("object is selectedE");
        pausePanning = true;
      },
      "selection:cleared": function () {
        pausePanning = false;
      },
      "touch:drag": function (e) {
        if (
          pausePanning == false &&
          undefined != e.e.layerX &&
          undefined != e.e.layerY
        ) {
          currentX = e.e.layerX;
          currentY = e.e.layerY;
          xChange = currentX - lastX;
          yChange = currentY - lastY;

          if (
            Math.abs(currentX - lastX) <= 50 &&
            Math.abs(currentY - lastY) <= 50
          ) {
            var delta = new fabric.Point(xChange, yChange);
            canvas.relativePan(delta);
          }

          lastX = e.e.layerX;
          lastY = e.e.layerY;
        }
      },
    });

    canvasRef.current.on("object:added", function (event) {
      let addedObject = event.target;
     
    });

    canvasRef.current.on("object:modified", function (event) {
      saveStateToUndoStack();
    });
  };
//Changing The Color of Canva BG




  let getFabricCanvases = (function () {
    let fabricCanvasCollection;
    return function getCanvases() {
      if (!fabricCanvasCollection) {
        fabricCanvasCollection = [];
        let fabricCanvas = document.querySelector(".canvas-container canvas");
        // console.log(fabricCanvas);
        // fabricCanvas.forEach((index, item) => {
        //     fabricCanvasCollection.push(item);
        // });
      }
      return fabricCanvasCollection;
    };
  })();

  const createGrid = (id) => {
    let grid = 50;
    //Create a grid on the screen.
    for (let i = 0; i < 4800 / grid; i++) {
      canvasRef.current.add(
        new fabric.Line([i * grid, 0, i * grid, 4800], {
          type: "line",
          stroke: "#f2f2f2",
          selectable: false,
          eraseable: false,
        })
      );
      canvasRef.current.add(
        new fabric.Line([0, i * grid, 4800, i * grid], {
          type: "line",
          stroke: "#f2f2f2",
          selectable: false,
          eraseable: false,
        })
      );
    }
    if (id) {
      let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
      emitCanvasData(id, cvData);
    }
    //Snap to grid functionality. Only enable this if
    //isSnapToGrid is set to true, otherwise print the grid but dont add the functionality

    if (isSnapToGrid) {
      canvasRef.current.on("object:moving", function (options) {
        options.target.set({
          left: Math.round(options.target.left / grid) * grid,
          top: Math.round(options.target.top / grid) * grid,
        });
      });

      canvasRef.current.on("object:modified", function (options) {
        let newWidth =
          Math.round((options.target.width * options.target.scaleX) / grid) *
          grid;
        let newHeight =
          Math.round((options.target.height * options.target.scaleY) / grid) *
          grid;

        options.target.set({
          width: newWidth,
          height: newHeight,
          scaleX: 1,
          scaleY: 1,
        });

        //scale objects to grid correctly
        // OnScaling(grid, canvasRef);
        saveStateToUndoStack();
      });
    }
  };

  const Panning = (roomID) => {
    // canvasRef.current.on("mouse:down", function (opt) {
    //   var evt = opt.e;
    //   if (evt.altKey === true) {
    //     this.isDragging = true;
    //     this.selection = false;
    //     this.lastPosX = evt.clientX;
    //     this.lastPosY = evt.clientY;
    //   }
    // });
    // canvasRef.current.on("mouse:move", function (opt) {
    //   if (this.isDragging) {
    //     var e = opt.e;
    //     var vpt = this.viewportTransform;
    //     vpt[4] += e.clientX - this.lastPosX;
    //     vpt[5] += e.clientY - this.lastPosY;
    //     this.requestRenderAll();
    //     this.lastPosX = e.clientX;
    //     this.lastPosY = e.clientY;
    //   }
    // });
    // canvasRef.current.on("mouse:up", function (opt) {
    //   // on mouse up we want to recalculate new interaction
    //   // for all objects, so we call setViewportTransform
    //   this.setViewportTransform(this.viewportTransform);
    //   this.isDragging = false;
    //   this.selection = true;
    //   let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    //   emitCanvasData(boardFileName, cvData);
    // });
    // canvasRef.current.on({
    //   'touch:gesture': function (event) {
    //     let zoomStartScale = 100;
    //     if (event.e.touches && event.e.touches.length == 2) {
    //         if (event.self.state == "start") {
    //             zoomStartScale =.getZoom();
    //         }

    //         var delta = zoomStartScale * event.self.scale;

    //         canvasRef.current.setZoom(delta);
    //         canvasRef.current.renderAll();
    //     }
    // }
    // })
    let deltaX = 0;
    let deltaY = 0;
    let panning;
    let isPanning;
    canvasRef.current.on("mouse:move", function (event) {
      if (isPanning && event.e && event && panning) {
        canvasRef.current.setCursor("grab");
        canvasRef.current.renderAll();
        canvasRef.current.selection = false;
        let movementX = event.e.movementX;
        if (deltaX >= 0) {
          deltaX -= movementX;
        }
        if (deltaX < 0) {
          movementX = deltaX + movementX;
          deltaX = 0;
        }
        let movementY = event.e.movementY;
        if (deltaY >= 0) {
          deltaY -= movementY;
        }
        if (deltaY < 0) {
          movementY = deltaY + movementY;
          deltaY = 0;
        }
        if (deltaX >= 0 && deltaY >= 0) {
          canvasRef.current.relativePan(new fabric.Point(movementX, movementY));
        }
      }
    });
    canvasRef.current.on("touch:drag", function (e) {
      if (isPanning && e.e && e && panning) {
        canvasRef.current.setCursor("grab");
        canvasRef.current.renderAll();
        canvasRef.current.selection = false;
        let movementX = e.e.movementX;
        if (deltaX >= 0) {
          deltaX -= movementX;
        }
        if (deltaX < 0) {
          movementX = deltaX + movementX;
          deltaX = 0;
        }
        let movementY = e.e.movementY;
        if (deltaY >= 0) {
          deltaY -= movementY;
        }
        if (deltaY < 0) {
          movementY = deltaY + movementY;
          deltaY = 0;
        }
        if (deltaX >= 0 && deltaY >= 0) {
          canvasRef.current.relativePan(new fabric.Point(movementX, movementY));
        }
      }
    });
    
    function bringToFront() {
      const activeObject = canvasRef.current.getActiveObject();
      if (activeObject) {

        activeObject.bringToFront();
        let objectID=activeObject.id
        emitBringToFront({DocID,activeObject,objectID})
        canvasRef.current.renderAll();
        saveStateToUndoStack()
      }
      closeContextMenu();
    }

    function sendToBack() {
      const activeObject = canvasRef.current.getActiveObject();
      if (activeObject) {

        activeObject.sendToBack();
        let objectID=activeObject.id
        emitSendToBack({DocID,activeObject,objectID})
        canvasRef.current.renderAll();
        saveStateToUndoStack()
      }
      closeContextMenu();
    }


    // Function to close the menu pop Up On RIght Click
    function closeContextMenu() {
      // Retrieve the context menu element by its ID
      const contextMenu = document.getElementById('context-menu');

      // Check if the context menu element exists
      if (contextMenu) {
        // Remove the context menu element from its parent node
        contextMenu.parentNode.removeChild(contextMenu);
      }
    }

    // Prevent the default context menu from appearing on the canvas
    canvasRef.current.on('contextmenu', function (e) {
      e.preventDefault();
    });

    // Event handler for mouse down on the canvas
    canvasRef.current.on('mouse:down', function (opt) {
      // Get the event and prevent the default context menu
      let evt = opt.e;
      evt.preventDefault();
      const contextMenu = document.getElementById('context-menu');

      // Check if the right mouse button is clicked
      if (opt.button === 3 && !contextMenu) {
        // Display the context menu for the clicked target
        if (opt.target) {
          showContextMenu(opt.target, evt);
        }
      }

      // Check if the Alt key is pressed
      if (evt.altKey === true) {
        // Set flags and update canvas properties for panning
        panning = true;
        isPanning = true;
        canvasRef.current.setCursor('grab');
        canvasRef.current.renderAll();
        canvasRef.current.selection = false;
      }
    });
    

    // Function to display the context menu at a specified position
    function showContextMenu(target, event) {
      // Create a new div element for the context menu
      const contextMenu = document.createElement('div');

      // Set styling and position properties for the context menu
      contextMenu.id = 'context-menu';
      contextMenu.style.width = '150px';
      contextMenu.style.position = 'absolute';
      contextMenu.style.top = `${event.clientY}px`;
      contextMenu.style.left = `${event.clientX}px`;
      contextMenu.style.background = 'white';
      contextMenu.style.border = '1px solid #ccc';
      contextMenu.style.padding = '13px';
      contextMenu.style.boxShadow = 'rgba(0, 0, 0, 0.54) 0px 3px 8px';
      contextMenu.style.borderRadius = '5px';

      // Create options for the context menu
      const bringToFrontOption = document.createElement('div');
      // bringToFrontOption.textContent = 'Bring to Front';
      bringToFrontOption.style.cursor = 'pointer';

      bringToFrontOption.addEventListener('mouseenter', () => {
        bringToFrontOption.style.fontWeight='600'  // Change the background color on hover
      });
      
      bringToFrontOption.addEventListener('mouseleave', () => {
        bringToFrontOption.style.fontWeight=''  // Reset the background color on mouse leave
      });

      // Create an img element for the icon
      const bringToFrontIcon = document.createElement('img');
      bringToFrontIcon.style.marginRight='8px'
      bringToFrontIcon.style.paddingRight='5px'
      bringToFrontIcon.style.width='20px'

      bringToFrontIcon.src =darkMode_?bringFrontWhite: bringToFrontImg; // Replace with the actual path to your icon image
      // sendToBackIcon.alt = 'Send to Back';

      // Create a text label for the button
      const bringToFrontText = document.createElement('span');
      bringToFrontText.textContent = 'Bring to Front';

      // Append the icon and text to the button
      bringToFrontOption.appendChild(bringToFrontIcon);
      bringToFrontOption.appendChild(bringToFrontText);

      bringToFrontOption.addEventListener('click', bringToFront);

      //Send TO Back Button 

      const sendToBackOption = document.createElement('div');
      // sendToBackOption.textContent = 'Send to Back';
      sendToBackOption.style.cursor = 'pointer';

      sendToBackOption.addEventListener('mouseenter', () => {
        sendToBackOption.style.fontWeight='600'  // Change the background color on hover
      });
      
      sendToBackOption.addEventListener('mouseleave', () => {
        sendToBackOption.style.fontWeight=''  // Reset the background color on mouse leave
      });

      // Create an img element for the icon
      const sendToBackIcon = document.createElement('img');
      sendToBackIcon.style.marginRight='8px'
      sendToBackIcon.style.paddingRight='5px'
      sendToBackIcon.src = darkMode_?SendBackWhite:SendtoBackImg; //  actual path to your icon image
      sendToBackIcon.style.width='20px'
      // sendToBackIcon.alt = 'Send to Back';

      // Create a text label for the button
      const sendToBackText = document.createElement('span');
      sendToBackText.textContent = 'Send to Back';

      // Append the icon and text to the button
      sendToBackOption.appendChild(sendToBackIcon);
      sendToBackOption.appendChild(sendToBackText);

      sendToBackOption.addEventListener('click', sendToBack);


      //Delete Button
      const deleteOption = document.createElement('div');
      // sendToBackOption.textContent = 'Send to Back';
      deleteOption.style.cursor = 'pointer';

      deleteOption.addEventListener('mouseenter', () => {
        deleteOption.style.fontWeight='600'  // Change the background color on hover
      });
      
      deleteOption.addEventListener('mouseleave', () => {
        deleteOption.style.fontWeight=''  // Reset the background color on mouse leave
      });

      // Create an img element for the icon
      const deleteOptionIcon = document.createElement('img');
      deleteOptionIcon.style.marginRight='9px'
      deleteOptionIcon.style.paddingRight='5px'
      deleteOptionIcon.src =darkMode_?deleteWhite: deleteIcon; //  actual path to your icon image
      deleteOptionIcon.style.width='19px'
      // sendToBackIcon.alt = 'Send to Back';

      // Create a text label for the button
      const deleteText = document.createElement('span');
      deleteText.textContent = 'Delete';

      // Append the icon and text to the button
      deleteOption.appendChild(deleteOptionIcon);
      deleteOption.appendChild(deleteText);

      deleteOption.addEventListener('click', deleteSelected);

      //Duplicate Menu
      const duplicateOption = document.createElement('div');
      // sendToBackOption.textContent = 'Send to Back';
      duplicateOption.style.cursor = 'pointer';

      duplicateOption.addEventListener('mouseenter', () => {
        duplicateOption.style.fontWeight = '600'  // Change the background color on hover
      });

      duplicateOption.addEventListener('mouseleave', () => {
        duplicateOption.style.fontWeight = ''  // Reset the background color on mouse leave
      });
      
      // Create an img element for the icon
      const duplicateOptionIcon = document.createElement('img');
      duplicateOptionIcon.style.marginRight = '9px'
      duplicateOptionIcon.style.paddingRight='5px'
      duplicateOptionIcon.src = darkMode_?duplicateWhite:copyIcon; //  actual path to your icon image
      duplicateOptionIcon.style.width = '20px'
      duplicateOptionIcon.style.height = '18px'

      // sendToBackIcon.alt = 'Send to Back';

      // Create a text label for the button
      const duplicateText = document.createElement('span');
      duplicateText.textContent = 'Duplicate';

      // Append the icon and text to the button
      duplicateOption.appendChild(duplicateOptionIcon);
      duplicateOption.appendChild(duplicateText);

      duplicateOption.addEventListener('click', duplicateSelection);

      //Group Menu
      const groupOption = document.createElement('div');
      // sendToBackOption.textContent = 'Send to Back';
      groupOption.style.cursor = 'pointer';

      groupOption.addEventListener('mouseenter', () => {
        groupOption.style.fontWeight = '600'  // Change the background color on hover
      });

      groupOption.addEventListener('mouseleave', () => {
        groupOption.style.fontWeight = ''  // Reset the background color on mouse leave
      });

      // Create an img element for the icon
      const groupOptionIcon = document.createElement('img');
      groupOptionIcon.style.marginRight = '8px'
      groupOptionIcon.style.paddingRight='5px'
      groupOptionIcon.src =darkMode_?groupWhite: groupIcon; //  actual path to your icon image
      groupOptionIcon.style.width = '20px'
      // sendToBackIcon.alt = 'Send to Back';

      // Create a text label for the button
      const groupText = document.createElement('span');
      groupText.textContent = 'Group';

      // Append the icon and text to the button
      groupOption.appendChild(groupOptionIcon);
      groupOption.appendChild(groupText);

      groupOption.addEventListener('click', handleGroup);


      //UnGroup Menu
      const unGroupOption = document.createElement('div');
      // sendToBackOption.textContent = 'Send to Back';
      unGroupOption.style.cursor = 'pointer';

      unGroupOption.addEventListener('mouseenter', () => {
        unGroupOption.style.fontWeight = '600'  // Change the background color on hover
      });

      unGroupOption.addEventListener('mouseleave', () => {
        unGroupOption.style.fontWeight = ''  // Reset the background color on mouse leave
      });

      // Create an img element for the icon
      const unGroupOptionIcon = document.createElement('img');
      unGroupOptionIcon.style.marginRight = '8px'
      unGroupOptionIcon.style.paddingRight='5px'
      unGroupOptionIcon.src = darkMode_?ungroupWhite:ungroup; //  actual path to your icon image
      unGroupOptionIcon.style.width = '20px'
      // sendToBackIcon.alt = 'Send to Back';

      // Create a text label for the button
      const unGroupText = document.createElement('span');
      unGroupText.textContent = 'Ungroup';

      // Append the icon and text to the button
      unGroupOption.appendChild(unGroupOptionIcon);
      unGroupOption.appendChild(unGroupText);

      unGroupOption.addEventListener('click', handleUngroup);

      // Append options to the context menu
      contextMenu.appendChild(bringToFrontOption);
      contextMenu.appendChild(sendToBackOption);
      contextMenu.appendChild(duplicateOption)
      contextMenu.appendChild(groupOption)
      contextMenu.appendChild(unGroupOption)
      contextMenu.appendChild(deleteOption);
      

      // Append the context menu to the document body
      document.body.appendChild(contextMenu);

      // Close the context menu when clicking outside of it
      window.addEventListener('click', function (event) {
        if (!contextMenu.contains(event.target)) {
          closeContextMenu();
        }
      });
    }
    const deleteSelected = () => {
      const activeObjects = canvasRef.current._activeObject;
      if (activeObjects) {
        if(activeObjects.type=='group'){
          let removedObjID = activeObjects.id;
          canvasRef.current.remove(activeObjects);
          emitDeleteObject(removedObjID);

        }
        if (activeObjects._objects) {
          activeObjects._objects.forEach((object) => {
            let removedObjID = object.id;
            canvasRef.current.remove(object);
            emitDeleteObject(removedObjID);




          })
        } else {
          let removedObjID = activeObjects.id;
          canvasRef.current.remove(activeObjects);
          emitDeleteObject(removedObjID);

        }
      }
      let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
      emitCanvasData(DocID, canvasData)
      canvasRef.current.discardActiveObject().renderAll();
      saveStateToUndoStack()
      closeContextMenu();
    }
    
    
    
   
    
    canvasRef.current.on("mouse:up", function (e) {
      panning = false;
      isPanning = false;
      canvasRef.current.setCursor("default");
      canvasRef.current.renderAll();
      canvasRef.current.selection = true;
      let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
      emitCanvasData(roomID, cvData);
    });
    canvasRef.current.on("mouse:wheel", function (opt) {
      let delta = opt.e.deltaY;
      let zoom = canvasRef.current.getZoom();
      zoom *= 0.999 ** delta;
      let zoomRatio = zoom;

      if (zoom >= 300 / 100) zoomRatio = 300 / 100;
      if (zoom <= 30 / 100) zoomRatio = 30 / 100;
      canvasRef.current.setZoom(zoomRatio);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
      emitCanvasData(roomID, cvData);
    });
  };

  // Zoom In
  const zoomIn = (roomID) => {
    let zoom = canvasRef.current.getZoom();
    zoom *= 0.999 ** -125;
    let zoomRatio = zoom;

    if (zoom >= 300 / 100) zoomRatio = 300 / 100;
    if (zoom <= 30 / 100) zoomRatio = 30 / 100;
    canvasRef.current.setZoom(zoomRatio);
    let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, cvData);
    // if(canvasRef.current.getZoom().toFixed(5) > zoomMax){
    //     console.log("zoomIn: Error: cannot zoom-in anymore");
    //     return;
    // }

    // canvasRef.current.setZoom(canvasRef.current.getZoom()*SCALE_FACTOR);
    // canvasRef.current.setHeight(canvasRef.current.getHeight()*SCALE_FACTOR);
    // canvasRef.current.setWidth(canvasRef.current.getWidth()*SCALE_FACTOR);
    // canvasRef.current.renderAll();
  };

  // Zoom Out
  const zoomOut = (id) => {
    let zoom = canvasRef.current.getZoom();
    zoom *= 0.999 ** 125;
    let zoomRatio = zoom;

    if (zoom >= 300 / 100) zoomRatio = 300 / 100;
    if (zoom <= 30 / 100) zoomRatio = 30 / 100;
    canvasRef.current.setZoom(zoomRatio);
    let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(id, cvData);
    // if( canvasRef.current.getZoom().toFixed(5) < 0.5){
    //     console.log("zoomOut: Error: cannot zoom-out anymore");
    //     return;
    // }

    // canvasRef.current.setZoom(canvasRef.current.getZoom()/SCALE_FACTOR);
    // canvasRef.current.setHeight(canvasRef.current.getHeight() / SCALE_FACTOR);
    // canvasRef.current.setWidth(canvasRef.current.getWidth() / SCALE_FACTOR);
    // canvasRef.current.renderAll();
  };

  // Reset Zoom
  const resetZoom = () => {
    canvasRef.current.setHeight(
      canvasRef.current.getHeight() / canvasRef.current.getZoom()
    );
    canvasRef.current.setWidth(
      canvasRef.current.getWidth() / canvasRef.current.getZoom()
    );
    canvasRef.current.setZoom(1);

    canvasRef.current.renderAll();

    getFabricCanvases().forEach(function (item) {
      item.css("left", 0);
      item.css("top", 0);
    });
  };

  //Going to refactor the code over here, to make it more readable
  const createImage = (imageURL, roomID) => {
    // console.log("From context ImageURL : ",imageURL) // for testing
    // console.log("From context Room ID : ",roomID) // for testing 

    let imgObj = new Image();
    imgObj.src = imageURL;
    const id = uuid();
    imgObj.onload = function () {
      const image = new fabric.Image(imgObj);
      const canvasWidth = canvasRef.current.width;
      const canvasHeight = canvasRef.current.height;
      const scaleFactor = 0.5; // 1/4 scale
      image.set({
        angle: 0,
        scaleX: canvasWidth * scaleFactor / image.width,
        scaleY: canvasHeight * scaleFactor / image.height,
        id: id,
      });
      canvasRef.current.add(image).renderAll();
      emitImageData({ room:DocID,id: id, image: image });
      let cvData = canvasRef.current.toJSON(["selectable", "id", "type"]);
      // debugger
      // console.log("After emitAdd Fcuntion from Socket", cvData);

      emitCanvasData(roomID, cvData);
      saveStateToUndoStack();
    };
  };

  function saveStateToUndoStack() {
    let state = JSON.stringify(
      canvasRef.current.toDatalessJSON(["selectable", "id", "type"])
    );
    undoStack.push(state);
  }
  function saveStateToReloadStack() {
    let state = JSON.stringify(
      canvasRef.current.toDatalessJSON(["selectable", "id", "type"])
    );
    reloadStack.push(state);
  }

  const createRectangle = (roomID) => {
    // console.log("createRectangle");

    // let pointer = canvas.getPointer(o.e);
    const rect = new fabric.Rect({
      left:isMobile?10: 100,
      top: 100,
      originX: "left",
      originY: "top",
      width: isMobile?200:400,
      height: isMobile?100:200,
      angle: 0,
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      selectable: true,
    });
    rect.set({ id: uuid() });
    rect.set({ type: "rect" });
    canvasRef.current.add(rect);
    canvasRef.current.setActiveObject(rect);
    emitAdd({ obj: rect,roomID, id: rect.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };

  const getActiveObject = () => {
    alert()
    canvasRef.current.on("object:selected", onObjectSelected);

    function onObjectSelected() {
      // check if type is a property of active element
      let objType = canvasRef.current.getActiveObject().type
        ? canvasRef.current.getActiveObject().type
        : "";
      // console.log(objType);
      // your code to process the object
    }
  };

  const createSquare = (roomID) => {
    let square = new fabric.Rect({
      width: isMobile?80:100,
      height: isMobile?80:100,
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      left:isMobile?10: 100,
      top: 200,
      selectable: true,
    });
    square.set({ id: uuid() });
    square.set({ type: "square" });
    canvasRef.current.add(square);
    canvasRef.current.setActiveObject(square).renderAll();
    emitAdd({ obj: square, id: square.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };

  const createEllipse = (roomID) => {
    let ellipse = new fabric.Ellipse({
      rx: isMobile?50:75,
      ry: isMobile?35:50,
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      left: isMobile?100:200,
      top: 200,
      selectable: true,
    });
    ellipse.set({ id: uuid() });
    ellipse.set({ type: "ellipse" });
    canvasRef.current.add(ellipse);
    canvasRef.current.setActiveObject(ellipse).renderAll();
    emitAdd({ obj: ellipse,roomID, id: ellipse.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };
  const createCircle = (roomID) => {
    let circle = new fabric.Circle({
      radius: isMobile?35:50,
      centeredRotation: true,
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      left: isMobile?100:200,
      top: 200,
      selectable: true,
    });
    circle.set({ id: uuid() });
    circle.set({ type: "circle" });
    canvasRef.current.add(circle);
    canvasRef.current.setActiveObject(circle).renderAll();
    emitAdd({ obj: circle,roomID, id: circle.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };
  const createTriangle = (roomID) => {
    let triangle = new fabric.Triangle({
      width: isMobile?75:100,
      height: isMobile?110:200,
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      left: 200,
      top: 200,
      selectable: true,
    });
    triangle.set({ id: uuid() });
    triangle.set({ type: "triangle" });
    canvasRef.current.add(triangle);
    canvasRef.current.setActiveObject(triangle).renderAll();
    emitAdd({ obj: triangle,roomID, id: triangle.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };
  const createRightAngleTriangle = (roomID) => {
    const points = [
      { x: 200, y: 200 }, // Top-left point
      { x: 200, y: 300 }, // Bottom-left point
      { x: 300, y: 300 }, // Bottom-right point
    ];
    
    const rightAngleTriangle = new fabric.Polygon(points, {
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      selectable: true,
    });
    rightAngleTriangle.set({ id: uuid() });
    rightAngleTriangle.set({ type: "right-angle-triangle" });
    canvasRef.current.add(rightAngleTriangle);
    canvasRef.current.setActiveObject(rightAngleTriangle).renderAll();
    emitAdd({ obj: rightAngleTriangle, id: rightAngleTriangle.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };
  const createCylinder = (roomID) => {
    const radius = 50;
    const height = 100; // Adjust the height as desired
    const centerX = 200;
    const centerY = 200;
    const topEllipse = new fabric.Ellipse({
      rx: radius,
      ry: radius / 2,
      fill: "",
      stroke: "#000",
      strokeWidth: 2,
      left: centerX,
      top: centerY - height / 2 - radius / 2,
      originX: "center",
      originY: "center",
      selectable: true,
    });
    const bottomEllipse = new fabric.Ellipse({
      rx: radius,
      ry: radius / 2,
      fill: "",
      stroke: "#000",
      strokeWidth: 2,
      left: centerX,
      top: centerY + height / 2 + radius / 2,
      originX: "center",
      originY: "center",
      selectable: true,
    });
    const sideLineLeft = new fabric.Line(
      [centerX - radius, centerY - height / 2 - radius / 2, centerX - radius, centerY + height / 2 + radius / 2],
      {
        fill: "",
        stroke: "#000",
        strokeWidth: 2,
        selectable: true,
      }
    );
    const sideLineRight = new fabric.Line(
      [centerX + radius, centerY - height / 2 - radius / 2, centerX + radius, centerY + height / 2 + radius / 2],
      {
        fill: "",
        stroke: "#000",
        strokeWidth: 2,
        selectable: true,
      }
    );
    const group = new fabric.Group([topEllipse, bottomEllipse, sideLineLeft, sideLineRight], {
      id: uuid(),
      type: "cylinder",
    });
    canvasRef.current.add(group);
    canvasRef.current.setActiveObject(group).renderAll();
    emitAdd({ obj: group, id: group.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  }; 
  const createDiamond = (roomID) => {
    const size = 100;
    const centerX = 200;
    const centerY = 200;
    const points = [
      { x: centerX, y: centerY - size / 2 }, // Top point
      { x: centerX + size / 2, y: centerY }, // Right point
      { x: centerX, y: centerY + size / 2 }, // Bottom point
      { x: centerX - size / 2, y: centerY }, // Left point
    ];
    const diamond = new fabric.Polygon(points, {
      fill: "",
      stroke: "#000",
      strokeWidth: 2,
      selectable: true,
    });
    diamond.set({ id: uuid() });
    diamond.set({ type: "diamond" });
    canvasRef.current.add(diamond);
    canvasRef.current.setActiveObject(diamond).renderAll();
    emitAdd({ obj: diamond, id: diamond.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };
  const createRightArrow = (roomID) => {
    const arrowPath = "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z";
    // const linePath = "M 0 15 L -40 15";
  
    // const combinedPath = `${arrowPath} ${linePath}`;
  
    const arrow = new fabric.Path(arrowPath, {
      left: 100,
      top: 100,
      fill: "",
      stroke: "#000",
      strokeWidth: 1,
      selectable: true,
    });
    arrow.set({ id: uuid() });
    arrow.set({ type: "arrow" });
    canvasRef.current.add(arrow);
    canvasRef.current.setActiveObject(arrow).renderAll();
    emitAdd({ obj: arrow, id: arrow.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };
  
  const createIText = (roomID, obj) => {
    let textOptions = {
      left: isMobile?100:200,
      top: 200,
      fill: "#000",
    };
    let textObject = new fabric.IText("Enter text here", textOptions);
    textObject.set({ id: uuid() });
    textObject.set({ type: "i-text" });
    canvasRef.current.add(textObject);
    canvasRef.current.renderAll();
    obj.setTextAlign(textOptions.textAlign);
    obj.setTextColor(textOptions.fill);
    obj.setTextFont(textOptions.fontFamily);
    obj.setTextSize(textOptions.fontSize);
    obj.setTextStyle(textOptions.fontStyle);
    emitText({ room:DocID,obj: textObject, id: textObject.id });
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(roomID, canvasData);
    saveStateToUndoStack();
  };

  const disableSelection = (selectionModeT) => {
    if (selectionModeT) {
      canvasRef.current.selection = selectionModeT;
      canvasRef.current.forEachObject(function (o) {
        if (o.type !== "line") {
          o.selectable = selectionModeT;
        }
      });
    } else {
      canvasRef.current.selection = selectionModeT;
      canvasRef.current.forEachObject(function (o) {
        if (o.type !== "line") {
          o.selectable = selectionModeT;
        }
      });
    }
    canvasRef.current.renderAll();
  };
  const Cylinder = fabric.util.createClass(fabric.Object, {
    type: 'cylinder',

    initialize(options) {
      options || (options = {});

      this.callSuper('initialize', options);
      this.set('radius', options.radius || 0);
      this.set('height', options.height || 0);
      this.set('fill', options.fill || 'transparent');
      this.set('stroke', options.stroke || '#000');
      this.set('strokeWidth', options.strokeWidth || 1);
      this.set('selectable', options.selectable !== undefined ? options.selectable : true);
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        radius: this.get('radius'),
        height: this.get('height'),
        fill: this.get('fill'),
        stroke: this.get('stroke'),
        strokeWidth: this.get('strokeWidth'),
        selectable: this.get('selectable'),
      });
    },

    _render(ctx) {
      // Draw cylinder using canvas methods
      const radius = this.get('radius');
      const height = this.get('height');

      ctx.beginPath();
      ctx.moveTo(radius, 0);
      ctx.lineTo(radius, height);
      ctx.arc(0, height, radius, Math.PI, 0, true);
      ctx.lineTo(-radius, 0);
      ctx.arc(0, 0, radius, 0, Math.PI, true);
      ctx.closePath();

      this.fillAndStroke(ctx); // Fill and stroke the shape
    },
  });

  fabric.Cylinder = Cylinder;

  fabric.Cylinder = Cylinder;
  const enableSelection = () => {
    canvasRef.current.selection = true;
    canvasRef.current.renderAll();
  };

  const convertCanvasToImage = (event) => {

    let link = event.currentTarget;

    //creating URL of canvas image

    let fullImage = canvasRef.current.toDataURL("image/png").replace("image/png", "image/octet-stream");
    
    //setting the link of anchor tag to the link that we create above
    
    link.setAttribute('href',fullImage);

    //setting default name of image to be downloaded

    link.download = "canvas-image.png"; 
  };
  
  //Function Below changes the BG color of canvas and sets the color as passed to it from Colorpicker @ Title.js

  const recolorBG = (roomID, newColor) => {
    let canvaBG = canvasRef.current;
    if (canvaBG) {
      canvasRef.current.set('backgroundColor', newColor);

      //Whole canvas will render after getting colored
      canvasRef.current.renderAll();

      // Emit the background color change to the server
      emitBackgroundColorChange(roomID,newColor);

      //deselecting activeobjects after recoloring shapes     
      canvasRef.current.discardActiveObject();

      let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
      emitCanvasData(roomID, canvasData);

      //State of canvas with Color will saved into Undo State
      saveStateToUndoStack();


    };


    }


  // Function below Recolors the Objects, takes the color arguement and sets the property of object to the color passed

  const recolorObjects = (newColor) => {

    let activeObjects = canvasRef.current.getActiveObjects();
    // console.log("Before Color getting Active ", activeObjects);      //Commented Code : Getting the selected objects at console
    
    if (activeObjects) {
      
      //activeObjects return multiple objects thus for each object to be colored we need to recolor all objects thus using foreach loop
      activeObjects.forEach(function (object) {
        if(object.type=='group'){
            object._objects.map((item)=>{if(item.type!=='i-text'){item.set('fill', newColor)}});
        }
        // console.log("object", object);
        object.set('fill', newColor);
        // object.set('stroke', newColor); // You can set the stroke color if needed
        emitShapeColorChange(DocID,newColor,object.id)
        let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
        emitCanvasData(DocID, canvasData);
      });

  
      canvasRef.current.renderAll();
      // console.log(canvasRef.current); // Commented Code : logged on console the selected object
      // canvasRef.current.discardActiveObject();
    
    }
                //State of Shapes of canvas with Color will saved into Undo State
                saveStateToUndoStack();

  };

  //Function below Changes Stroke/Border of selected Objects/Items
  const recolorStroke = (newColor) => {

    let activeObjects = canvasRef.current.getActiveObjects();
    // console.log("Before Color getting Active ", activeObjects);      //Commented Code : Getting the selected objects at console
    
    if (activeObjects) {
      
      //activeObjects return multiple objects thus for each object to be colored we need to recolor all objects thus using foreach loop
      activeObjects.forEach(function (object) {
        if(object.type=='group'){
            object._objects.map((item)=>{if(item.type!=='i-text'){item.set('stroke', newColor)}});
        }

        object.set('stroke', newColor); // You can set the stroke color if needed
        emitStrokeColorChange(DocID,newColor,object.id)
        let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
        emitCanvasData(DocID, canvasData);
      });

  
      canvasRef.current.renderAll();
      // console.log(canvasRef.current); // Commented Code : logged on console the selected object
      // canvasRef.current.discardActiveObject();
    
    }
                //State of Shapes of canvas with Color will saved into Undo State
                saveStateToUndoStack();

  };

//    Function Below returns the active Object on Canvas and is called at Title.JS  

  function setColorpickerVisiblity(){
    if(canvasRef.current)
    { 
      return canvasRef.current._activeObject;
    }
  }

 /**
* Function Below:
 * Duplicates the currently selected objects on the canvas and adds the duplicates slightly offset.
 * This function retrieves the active objects on the canvas and creates clones of each object,
 * positioning the clones with a small offset from their original positions. The duplicates are then
 * added to the canvas, and the canvas state is updated accordingly.
 * 
 * @remarks
 * The function also triggers the saving of the current canvas state to the undo stack to allow for
 * subsequent undo operations.
 * 
 * @param {void}
 * @returns {void}
 */
  const duplicateSelection = () => {
    // Retrieve the currently active objects on the canvas
    const activeObjects = canvasRef.current.getActiveObjects();

    if (activeObjects) {
      // Iterate through each active object
      activeObjects.forEach(function (object) {
        // console.log("From Duplicate", object);

        // Clone the object and add the clone to the canvas with an offset
        object.clone(function (clone) {
          canvasRef.current.add(clone.set({
            id: uuid(),
            left: object.left + 20,
            top: object.top + 20,
            fill:object.fill
          }));
          if(clone.type!=='image'){
        emitClonedObj(clone.id,clone,DocID)
      }else{
        emitImageData({room:DocID,id:clone.id,image:clone})
}
        });
        // let objectInfo={object.id}
        // Emiting to show copy the duplication in other clients
        console.log("Object From Context",object)



      });
  }

  // Save the current canvas state to the undo stack
  saveStateToUndoStack();

    // Render the updated canvas state
    canvasRef.current.renderAll();

    //   let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    // emitCanvasData(DocID, canvasData);

    // Discard the active object selection
    canvasRef.current.discardActiveObject();
  };

const [documentData,setDocumentData]=useState(null);
const [userInfo,setUserInfo]=useState(null);
  /**
   * Clears the canvas by removing all objects from it.
   */
  const clearCanvas = () => {
    // Use the canvasRef to access the current canvas instance.
    // Call the remove method on the canvas instance to remove all objects.
    canvasRef.current.set('backgroundColor', '');

    let clearedCanvas = canvasRef.current.remove(...canvasRef.current.getObjects());
    //Emitter to clear canvas sent to server here
    emitClearCanvas({DocID, clearedCanvas});
    //Saving the Data of Canvas into File 
    let canvasData = canvasRef.current.toJSON(["selectable", "id", "type"]);
    emitCanvasData(DocID, canvasData);

    //Saving state in Undo Stack so on Undo it retrieves from Stack
    saveStateToUndoStack();

};


  const handleUngroup = () => {
    if (!canvasRef.current.getActiveObject()) {
      return;
    }
    if (canvasRef.current.getActiveObject().type !== "group") {
      return;
    }
    let activeObjects=canvasRef.current
    let groupId=canvasRef.current.getActiveObject().id
    if(activeObjects._activeObjects){
      // groupId=activeObjects._activeObjects.id
    }
    // emitObjID(objID)
    canvasRef.current.getActiveObject().toActiveSelection();
    // console.log('groupId',groupId)
    emitUnGroup({groupId})
    canvasRef.current.requestRenderAll();
  };


    const handleGroup = () => {
    if (!canvasRef.current.getActiveObject()) {
      return;
    }
    if (canvasRef.current.getActiveObject().type !== "activeSelection") {
      return;
    }
    let objIDArr=[]
    let objArr = [...canvasRef.current.getActiveObjects()];
    canvasRef.current.getActiveObjects().forEach((obj)=>{
    objIDArr.push(obj.id)
    })
    //let objIDArr = [];
    console.log('objIDArr :',objIDArr+'/n objArray :',objArr)
    let groupId=uuid()
    canvasRef.current.getActiveObject().toGroup().set({id:groupId});
    emitObjID({groupId,objIDArr,objArr});

    canvasRef.current.requestRenderAll();
  };
  let darkMode_;

  const setMode = (darkMode) => {
    darkMode_=darkMode

    // console.log('darkMode : ',darkMode)
    // alert('Dark Mode')
    if (darkMode) {
      enableDarkMode({
        brightness: 100,
        contrast: 90,
        sepia: 10,
      });
    } else {
      disableDarkMode();

    }
  }
  // Function to Detect any activity to set var saving... and Saved
  const savingLastChanges = (change) => {
    lastChangeRef.current = change;
  };
  return (
    <CanvasContext.Provider
      value={{
        savingLastChanges,
        lastChange: () => lastChangeRef.current, // Function to retrieve the value
        darkMode_,
        setMode,
        recolorStroke,
        handleGroup,
        handleUngroup,
        saveStateToReloadStack,
        reloadStack,
        DocID,
        documentData,
        userInfo,
        setDocumentData,
        clearCanvas,
        saveStateToUndoStack,
        duplicateSelection,
        setColorpickerVisiblity,
        recolorObjects,
        recolorBG,
        canvasRef,
        prepareCanvas,
        createRectangle,
        createTriangle,
        createCircle,
        createIText,
        createGrid,
        setSnapToGrid,
        isSnapToGrid,
        Panning,
        zoomIn,
        zoomOut,
        resetZoom,
        createImage,
        getActiveObject,
        disableSelection,
        enableSelection,
        convertCanvasToImage,
        undoStack,
        redoStack,
        createSquare,
        createEllipse,
        createCylinder,
        createRightAngleTriangle,
        createDiamond,
        createRightArrow,
        setUserInfo,
        setIsChangeSomething,
        isChangeSomething
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => useContext(CanvasContext);
