import { React, useEffect } from "react";
import { useCanvas } from "../context/canvasContext";
import useMousePosition from "../hooks/useMousePosition";

const ChangesDetector = ({ handleDetect, selectedObject }) => {
  const { x, y } = useMousePosition()
  const { setColorpickerVisiblity } = useCanvas();

  useEffect(() => {
    let getSelectedObject = setColorpickerVisiblity()
    if ((!!selectedObject !== !!getSelectedObject))
      handleDetect(getSelectedObject)
  }, [x, y])

  return <></>

};

export default ChangesDetector;
