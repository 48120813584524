// Importing the axios library for making HTTP requests
import axios from "axios";

// Importing a helper function to retrieve the WorkWise authentication token
import { formatFileSize, getWorkWiseAuthToken } from "./utils/helper";

// Defining server URLs for different APIs
export const serverUrl = "http://localhost:3100/padApi/v2"; // Local server URL
export const workwiseUrl = "https://workw.com/workwapi/api"; // WorkWise API URL
export const localWorkwUrl = 'https://58.65.211.234:4436/konnectapi/api'; // Local WorkWise API URL  

// export const workwiseUrl = "https://58.65.211.234:4436/Konnectapi"; // Alternative WorkWise API URL
// export const serverUrl = "https://pad.workw.com"; // Alternative server URL

// Creating headers with WorkWise authentication token
const workwiseHeaders = { 'Authorization': `Bearer ${getWorkWiseAuthToken()}` };
let debounceTimeout;
// Exporting a collection of WorkWise-related services
export const workwiseServices = {
  /**
   * Retrieves a document by its ID from the WorkWise API.
   *
   * @param {string} id - The ID of the document to retrieve.
   * @returns {Promise} A Promise that resolves with the response data.
   */
  async getDocumentById(id) {

    // Todo : To be Used When Live is Down

    // ! const dummyResponse = {
    // !  status: 200,
    // !  data: {
    // !    data: {
    // !      rightType: 2,
    // !      members: [],
    // !      name: "Dummy",
    // !      creator: {
    // !        id: "dss",
    // !        name: "YUP",
    // !        image: "tt"
    // !      },
    // !      createBy: "dsds"
    // !    }
    // !  }
    // !}
    // ! return dummyResponse;
    return axios.get(
      `${workwiseUrl}/Authorize/Authorize?id=${id}`,
      { headers: workwiseHeaders }
    );
  },
  async getAllSuggestion(payload) {
    return axios.post(
      `${workwiseUrl}/Suggestion/GetAllSuggestion`,
      payload,
      { headers: workwiseHeaders });
  },
  async addDocumentMember(id, payload) {
    return axios.post(
      `${workwiseUrl}/Document/AddDocumentMember?id=${id}`,
      payload,
      { headers: workwiseHeaders });
  },
  async shareDocumentToAnyone(payload) {
    return axios.post(`${workwiseUrl}/Document/UpdateDocument`,
      payload,
      { headers: workwiseHeaders }
    )
  },
  async saveDocumentlastUpdate(id, setSavingData, data) {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const size = new TextEncoder().encode(JSON.stringify(data)).length || 0;
    const sizeWithUnit = formatFileSize(size);

    if (setSavingData) {
      setSavingData({ isSaving: 'Saving...', lastSavedDateTime: '' });

      await new Promise(resolve => setTimeout(resolve, 600));

      setSavingData({
        isSaving: 'Saved',
        lastSavedDateTime: formattedDate,
        fileSize: sizeWithUnit
      });
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        await axios.post(`${workwiseUrl}/Document/SaveDocumentAttachment?attachmentId=${id}&fileSize=${size}`,
          {},
          { headers: workwiseHeaders }
        );
        // Handle the response if needed
      } catch (e) {
        console.error("error", e);
      }
    }, 5000);
  },
  async completeDocument(id) {
    try {
      const formData = new FormData();
      formData.append('id', id);

      await axios.post(`${workwiseUrl}/Document/CompleteDocument?id=${id}`,
        formData,
        { headers: workwiseHeaders }
      );
      // Handle the response if needed
    } catch (e) {
      console.error("error", e);
    }
  },

};
