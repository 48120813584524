import React, { useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { getWorkWiseAuthToken } from "../utils/helper";
import { workwiseServices } from "../api";
import { useCanvas } from "../context/canvasContext";
import Loader from "../components/SkeletonLoader/skeletonLoader";
import { useDocument } from "../context/documentInfoContex";


// import Loader from "./Loader";
export const AuthenticateDocument = ({ setIsLoading }) => {

  //State from CanvasContext used to store response and set the components according to this data
  const { setDocumentData, setUserInfo } = useCanvas();
  const { setIsReadOnly } = useDocument();


  const history = useHistory();
  const { id } = useParams();
  const handleAuthenticate = async () => {
    try {
      setIsLoading(true);
      const searchParams = new URLSearchParams(document.location.search);
      const isReadOnlyQuery = searchParams.get("readOnly");
      if (!window.navigator.onLine) {
        window.location.pathname="/no-internet"
        return;
      }
      else {
        if (!!getWorkWiseAuthToken()) {
          const response = await workwiseServices.getDocumentById(id);

          if (response?.status == 200 && response.data?.data?.rightType !== 0 && response.data?.data) {
            //Store the response data in a state using UseState Hook created in CanvasContext

            setDocumentData(response.data.data);

            setIsLoading(false);

            //Store the response data in a state using UseState Hook created in CanvasContext

            setDocumentData(response.data.data);
            if (response.data.data.rightType == 1 || isReadOnlyQuery == "true") {
              setIsReadOnly(true);
            }
          }
          if (response.data?.data?.rightType == 0) {
            // history.push("/unauthorized")
            window.location.pathname="/unauthorized"
          }
          // history.push("/unauthorized")
        }
        else {
          // history.push("/unauthorized")
          window.location.pathname="/unauthorized"
        }
      }
    } catch (error) {
      console.log({ error })
      if (error.response?.data?.responseCode === 1008) {
        window.location.pathname="/invalid-document"
      }
      else {
        // history.push("/unauthorized")
        window.location.pathname="/unauthorized"

      }
    }
  }
  useEffect(() => {
    let info = JSON.parse(Buffer.from(getWorkWiseAuthToken().split('.')[1], 'base64').toString());
    setUserInfo(info);
    handleAuthenticate()
  }, []);
  return <div><Loader /></div>

}