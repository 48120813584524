import React, { createContext, useContext, useState } from "react";
const DocumentContext = createContext();

export const DocumentInfoProvider = ({ children }) => {
  const [savingData, setSavingData] = useState({
    isSaving: '',
    lastSavedDateTime: "",
    fileSize:0,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);

  return (
    <DocumentContext.Provider
      value={{ savingData, setSavingData,isReadOnly,setIsReadOnly }}>
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocument = () => {
  return useContext(DocumentContext);
};
