import { useState, React, useEffect } from "react";
import logo from "../assets/mileboard-logo.svg";
import exportLogo from "../assets/export-2.svg";
import { ReactComponent as ClearAll } from '../assets/clear_all.svg'
import { ReactComponent as ColorPicker } from '../assets/colorPicker.svg'
import privateIcon from '../assets/lock.svg';
import publicIcon from '../assets/world.svg';
import stroke from '../assets/stroke.png'
import { Button, Tooltip } from 'antd';
import { BgColorsOutlined, BorderBottomOutlined, BorderOuterOutlined } from '@ant-design/icons';
import { useCanvas } from "../context/canvasContext";
import { useParams } from "react-router-dom";
import ChangesDetector from "./ChangesDetector";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import TitleStyles from './Title.css'
import SavingSaved from "./SavingSaved/SavingSaved";
import PrintBoard from "./Print/printBoard";
import { useDocument } from "../context/documentInfoContex";
import CompleteDocument from "./CompleteDoc/completeDoc";

const Title = ({ isMobile }) => {
  const [offline, setOffline] = useState(false);
  const { isReadOnly } = useDocument();


  useEffect(() => {
    const handleOfflineStatus = () => {
      setOffline(!window.navigator.onLine);
    };

    window.addEventListener("offline", handleOfflineStatus);
    window.addEventListener("online", handleOfflineStatus);

    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener("offline", handleOfflineStatus);
      window.removeEventListener("online", handleOfflineStatus);
    };
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const { recolorObjects, recolorBG, convertCanvasToImage, documentData, clearCanvas, recolorStroke, darkMode_, lastChange } = useCanvas();
  //Function below returns an object is passed to variable below, If It has object the ColorPicker will showother wise It will be disable
  const [selectedObject, setSelectedObject] = useState(null)

  const myParam = urlParams.get('mileboardname');
  const { id } = useParams()
  // console.log("KOI AAYA")
  // console.count("KOI AAYA")

  const handleDetect = (getSelectedObject) => {
    setSelectedObject(getSelectedObject)
  }

  return (<>
    <div style={{ display: "flex" }}>
      <div className={TitleStyles.titleWrapper}
        style={{
          width: isMobile ? '270px' : "max-content",
          height: isMobile ? '44px' : "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          boxShadow: 'rgb(101 92 92 / 50%) 0px 3px 8px',
          borderRadius: '5px',
          padding: 20,
          position: "relative",
          zIndex: 1
        }}
      >
        <img
          src={logo}
          width={isMobile ? '20px' : "30px"}
          height="30px"
          style={{ marginRight: isMobile ? '0px' : 20, position: isMobile ? 'relative' : '', left: isMobile ? -5 : '' }}
        />
        <div
          style={{
            height: 30,
            width: 2,
            marginRight: isMobile ? '4px' : 20,
            backgroundColor: "#757D86",
          }}
        ></div>
        <h1
          style={{
            fontSize: isMobile ? 10 : 15,
            fontFamily: "Montserrat",
            fontWeight: "400",
            paddingTop: isMobile ? 7 : 10,
            width: "max-content",

            marginRight: isMobile ? 5 : 10,
          }}
        >
          {documentData ? documentData.name : "New Board"}
        </h1>
        <div style={{ marginLeft: 10 }}>
          {documentData?.privacyId == 2 ? (
            <>
              <img src={privateIcon} alt='Private documnet' style={{ width: '12px', paddingBottom: 4 }} />
            </>
          )
            : (<> <img src={publicIcon} alt='Public documnet' style={{ width: '15px', paddingBottom: 4 }} /></>)}
        </div>
        <PrintBoard />
        {
          !isReadOnly && <CompleteDocument />
        }
        {/* <div
        style={{
          height: 30,
          width: 2,
          marginRight: 20,
          backgroundColor: "#757D86",
        }}
      ></div>
      <div style={{ display: "flex" }}>
        <img
          src={settingsLogo}
          width="20"
          height="20"
          alt="settings Logo"
          style={{ marginRight: 20 }}
        />
        {/* Calling convertCanvasToImage on below anchor tag img, It will prompt download "download prop is must here" */}

        <Tooltip title="Download as Image" placement='top'>
          <a id="download-image-link" href="download-link" download onClick={(e) => convertCanvasToImage(e)}>

            <Button style={{ width: isMobile ? '30px' : '45px', marginLeft: 10, marginRight: 4 }} icon={<FileDownloadOutlinedIcon style={{ width: isMobile ? 18 : 22 }} />}>
              {/* <img
                  // onClick={convertCanvasToImage}
                  src={<FileDownloadOutlinedIcon/>}
                  width="65"
                  height="68"
                  alt="export Logo"
                  style={{position:"relative", cursor: "pointer", top:'-23px',left:'-25px'}}
                /> */}
            </Button>
          </a>
        </Tooltip>

        {//checks if Rights are as Reader or Collaborator then renders the below elements
          !isReadOnly &&
          <>
            <Tooltip title="Change Canvas Color" placement='top'>
              <Button style={{ width: isMobile ? '30px' : '45px', marginRight: 4 }} icon={<FormatColorFillOutlinedIcon style={{ width: isMobile ? 12 : 15, marginLeft: isMobile ? -6 : '' }} />} >
                <input type="color"
                  style={{
                    opacity: 0, // Make the color picker input invisible
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }} // Hide the default color input display

                  //recolorBG passes color into properties of objects, Defined @ CanvasContext

                  onChange={(e) => recolorBG(id, e.target.value)}
                ></input>

              </Button>
            </Tooltip>
            {/* Button for clear all on Canvas */}
            <Tooltip title="Clear Canvas" placement='top'>

              <Button style={{ width: isMobile ? '30px' : '45px', marginRight: 4 }} icon={<ClearAll style={{ fill: darkMode_ ? 'rgba(13, 13, 13, 0.2) 0px 2px 0px' : '#000', width: isMobile ? 40 : 50, position: 'relative', top: isMobile ? -7 : -11, left: isMobile ? -5 : -3 }} />}
                onClick={clearCanvas}>
              </Button>
            </Tooltip>

            {/* If selectedObject has an object Button will appear on Header */}
            {/* If Selected Object is Image or Path ColorPicker wont appear */}
            {selectedObject && selectedObject.type != 'image' &&
              <>
                <Tooltip title="Change Shape Color" placement='top'>
                  <Button style={{ width: isMobile ? '30px' : '45px', marginRight: 4 }} icon={<ColorLensOutlinedIcon style={{ width: isMobile ? 14 : 18, marginLeft: isMobile ? -7 : -2 }} />}  >

                    <input type="color"
                      style={{
                        opacity: 0, // Make the color picker input invisible
                        position: 'absolute',
                        right: isMobile ? 20 : 0,
                        top: 0,
                        width: '100%',
                        height: '90%',
                        cursor: 'pointer',
                      }} // Hide the default color input display

                      //recolorObjects passes color into properties of objects, Defined @ CanvasContext

                      onChange={(e) => recolorObjects(e.target.value)}
                    ></input>

                  </Button>
                </Tooltip>

                <Tooltip title="Change Border Color" placement='top'>
                  <Button style={{ width: isMobile ? '30px' : '45px', marginRight: 4 }} icon={<BorderStyleIcon style={{ width: isMobile ? 15 : 20, marginLeft: isMobile ? -7 : -3 }} />}  >

                    <input type="color"
                      style={{
                        opacity: 0, // Make the color picker input invisible
                        position: 'absolute',
                        right: isMobile ? 20 : 0,
                        top: 0,
                        width: '90%',
                        height: '90%',
                        cursor: 'pointer',
                      }} // Hide the default color input display

                      //recolorObjects passes color into properties of objects, Defined @ CanvasContext

                      onChange={(e) => recolorStroke(e.target.value)}
                    ></input>

                  </Button>
                </Tooltip>
              </>
            }
            <SavingSaved />
          </>
        }
        <ChangesDetector handleDetect={handleDetect} selectedObject={selectedObject} />
      </div>
    </div>
    {offline &&
      <div className="offlineStatus">
        <h5>No Internet, Your changes are not being saved</h5>
      </div>
    }
  </>
  );
};

export default Title;
