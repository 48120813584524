import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { CanvasProvider } from "./context/canvasContext";
import { BoardFileProvider } from "./context/boardFileContext";
import ResultComponent from './components/ResultComponent/ResultComponent';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DocumentInfoProvider } from "./context/documentInfoContex";



ReactDOM.render(
  <React.StrictMode>
    <BoardFileProvider>
      <CanvasProvider>
        <DocumentInfoProvider>
          <Router>
            <Switch>
              <Route path="/invalid-document" >
                <ResultComponent title="Document Verification Failed , Invalid Document. " />
              </Route>
              <Route
                path="/unauthorized">
                <ResultComponent title="Security Verification Failed , Unauthorized Access. " />
              </Route>
              <Route path="/:id" ><App /></Route>

            </Switch>
          </Router>
        </DocumentInfoProvider>
      </CanvasProvider>
    </BoardFileProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
