import { Input } from "antd";
import { message } from 'antd';
import { CopyOutlined } from "@ant-design/icons";
// import "antd/dist/antd.css";

export const ShareInput = ({ contentToggle }) => {
  const sharedUrl = window.location.href + "?r=true";
  const copyLinkIcon = <CopyOutlined onClick={() =>{navigator.clipboard.writeText(sharedUrl); message.success("Link copied to clipboard")}} />;
  return (
    <>
      {contentToggle ? (
        <Input placeholder="Add people and groups"/>
      ) : (
        <Input size="small" suffix={copyLinkIcon} value={sharedUrl}/>
      )}
    </>
  );
};
