import { useState } from 'react';
import { Button, Menu, Collapse, Typography, Form, Select, Input, Slider,  } from 'antd';
import { FontSizeOutlined, BoldOutlined, ItalicOutlined, UnderlineOutlined, StrikethroughOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useCanvas } from '../../context/canvasContext';

const { Panel } = Collapse;
const { Option } = Select;
const { Title } = Typography;

const TextMenu = ({textColor, setTextColor, textSize, setTextSize, textFont, setTextFont, textAlign, setTextAlign, textStyle, setTextStyle,setTextMenuVisible,isTextMenuVisible}) => {
const isMobile=window.screen.width<768
  const {canvasRef} = useCanvas();
 
    const handleTextColorChange = (e) => {
      const activeObject = canvasRef.current.getActiveObject();
      if (activeObject && activeObject.isType('i-text')) {
        activeObject.set('fill', e.target.value);
        canvasRef.current.renderAll();
        setTextColor(e.target.value);
      }
      };
    
      const handleTextSizeChange = (value) => {
        const activeObject = canvasRef.current.getActiveObject();
        if (activeObject && activeObject.isType('i-text')) {
          activeObject.set('fontSize', value);
          canvasRef.current.renderAll();
          setTextSize(value);
        }
      };
    
      const handleTextFontChange = (value) => {
        const activeObject = canvasRef.current.getActiveObject();
        if (activeObject && activeObject.isType('i-text')) {
          activeObject.set('fontFamily', value);
          canvasRef.current.renderAll();
          setTextFont(value);
        }
      };
    
      const handleTextAlignChange = (value) => {
        const activeObject = canvasRef.current.getActiveObject();
        if (activeObject && activeObject.isType('i-text')) {
          activeObject.set('textAlign', value);
          canvasRef.current.renderAll();
          setTextAlign(value);
        }
      };
    
      const handleTextStyleChange = (value) => {
        const activeObject = canvasRef.current.getActiveObject();
        if (activeObject && activeObject.isType('i-text')) {
          activeObject.set('fontWeight', value.includes('bold') ? 'bold' : 'normal');
          activeObject.set('fontStyle', value.includes('italic') ? 'italic' : 'normal');
          activeObject.set('textDecoration', value.includes('underline') ? 'underline' : 'none');
          canvasRef.current.renderAll();
          setTextStyle(value);
        }
      };

      const handleCollapseChange = (activeKeys) => {
        // Check if '1' (key of the "Text Properties" Panel) is in the activeKeys array
        const isTextMenuVisible = activeKeys.includes('1');
        setTextMenuVisible(isTextMenuVisible);
      };

      return (
        <Collapse   bordered={true}
        defaultActiveKey={['1']}
        expandIconPosition="right"
      activeKey={isTextMenuVisible ? ['1'] : []} // Set activeKey based on the visibility state
      onChange={handleCollapseChange} // Handle the collapse change event
      style={{width:isMobile?'125px':'168px',position:isMobile?'absolute':'',top:isMobile?'-620px':'',right:isMobile?'135px':'',}}
      >
        <Panel header="Text Properties" key="1" style={{fontSize:isMobile?'11px':'16px'}}>
          <Title style={{fontSize:isMobile?'11px':'16px'}} level={5}>Text Color</Title>
          <Form.Item>
            <Input style={{width:isMobile?'80px':'112px'}} type="color" defaultValue={textColor} onChange={handleTextColorChange} />
          </Form.Item>
          <Title style={{fontSize:isMobile?'11px':'16px'}} level={5}>Text Size</Title>
          <Form.Item>
            <Slider style={{width:isMobile?'80px':'112px'}} min={10} max={100} defaultValue={textSize} onChange={handleTextSizeChange} />
          </Form.Item>
          <Title style={{fontSize:isMobile?'11px':'16px'}} level={5}>Text Font</Title>
          <Form.Item>
            <Select style={{width:isMobile?'80px':'112px'}} defaultValue={textFont} onChange={handleTextFontChange}>
              <Option value="Arial">Arial</Option>
              <Option value="Times New Roman">Times New Roman</Option>
              <Option value="Comic Sans MS">Comic Sans MS</Option>
              <Option value="Tahoma">Tahoma</Option>
              <Option value="Verdana">Verdana</Option>
            </Select>
          </Form.Item>
          <Title style={{fontSize:isMobile?'11px':'16px'}} level={5}>Text Alignment</Title>
          <Form.Item style={{padding:5}}>
            <AlignLeftOutlined className={textAlign === 'left' ? 'active' : ''}  style={{fontSize:isMobile?12:20,marginRight:isMobile?10:20}}  onClick={() => handleTextAlignChange('left')} />
            <AlignCenterOutlined className={textAlign === 'center' ? 'active' : ''} style={{fontSize:isMobile?12:20,marginRight:isMobile?10:20}}  onClick={() => handleTextAlignChange('center')} />
            <AlignRightOutlined className={textAlign === 'right' ? 'active' : ''} style={{fontSize:isMobile?12:20,marginRight:isMobile?10:20}} onClick={() => handleTextAlignChange('right')} />
          </Form.Item>
          <Title style={{fontSize:isMobile?'11px':'16px'}} level={5}>Text Style</Title>
          <Form.Item>
            <Button.Group>
              <Button style={{width:isMobile?'27px':'42px',padding:isMobile?'2px 6px':'4px 15px'}} type={textStyle === 'bold' ? 'primary' : 'default'} onClick={() => handleTextStyleChange('bold')}>
                <BoldOutlined />
              </Button>
              <Button style={{width:isMobile?'27px':'42px',padding:isMobile?'2px 6px':'4px 15px'}} type={textStyle === 'italic' ? 'primary' : 'default'} onClick={() => handleTextStyleChange('italic')}>
                <ItalicOutlined />
              </Button>
              <Button style={{width:isMobile?'27px':'42px',padding:isMobile?'2px 6px':'4px 15px'}} type={textStyle === 'underline' ? 'primary' : 'default'} onClick={() => handleTextStyleChange('underline')}>
                <UnderlineOutlined />
              </Button>
            </Button.Group>
          </Form.Item>
        </Panel>
      </Collapse>
      );
};

export default TextMenu
