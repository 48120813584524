import { Avatar, Image,Button,message } from "antd";
import iconImage from "../../../assets/iconImage.png";
import classes from "./SharePrivacy.module.css";
// import "antd/dist/antd.css";
import { DropDownMenu } from "./DropDownMenu/DropDownMenu";
import ShareForm from "./ShareForm";
import { useCanvas } from "../../../context/canvasContext";
import { workwiseServices } from "../../../api";
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export const SharePrivacy = ({ contentToggle,setIsModalVisible,privacy,setPrivacy }) => {
  const { documentData, setDocumentData}=useCanvas()


  const mileBoardName = documentData.name;

  const {id}=useParams()
  const privacyId=1
  const payload={attachmentId: id,name : mileBoardName,privacyId}

  const callShareDocumentToAnyone=async()=>{
   await workwiseServices.shareDocumentToAnyone(payload)
    message.success("Document is shared as Public")
    setIsModalVisible(false)

    const response = await workwiseServices.getDocumentById(id);
    if (response?.status==200 && response.data?.data?.rightType !== 0 && response.data?.data) {

      setDocumentData(response.data.data);
    }

  }
  return (
    <div className={classes.ownerInfo}>
    <div className={classes.avatar}>
      <Avatar size="large" src={<Image src={iconImage} />} />
    </div>
    <div className={classes.info}>
      <div className={classes.name}>
        {contentToggle ? (
          <h4>Name</h4>
        ) : (
          <DropDownMenu
            menuItems={["Restricted", "Anyone in the Company"]}
            placement="bottomLeft"
            type={privacy}
            setType={setPrivacy}
          />
        )}
      </div>
      <div className={classes.email}>
        {privacy === "Restricted" ? (
          <>
            <ShareForm setIsModalVisible={setIsModalVisible} setPrivacy={setPrivacy} />
          </>
        ) : (
          <>
            <p>Anyone in the Company with the link can access
            </p>
            <Button style={{ marginTop: 10, marginLeft: 'auto', display: 'block' }} size="small" shape="round" onClick={callShareDocumentToAnyone} className='share-button' type="primary" htmlType="submit">
              Share
            </Button>
          </>
        )}
      </div>
    </div>
  </div>
  );
};
